.aaa-ees {
    height: 320px;
    width: 200px;
    background: white;
    border-radius: 20px;
}

.skl {
    width: 100%;
}

.aso-aa {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.sk-aaw {
    height: 350px;
    width: 230px;
    filter: brightness(0.8);
}

.aaa-ees.sla.main-page:hover {
    filter: brightness(1);
    transform: scale(1.02);
    transition: transform 0.2s ease-in-out;

}

.aaa-ees.sk-aaw.main-page:hover {
    filter: brightness(1);
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
}

.ps-lle {
    position: relative;
    right: 80px;
    z-index: 50;

}

.as-ssw.ps-llw.token-swew.collection-buddy {
    position: unset;
}

.as-ssw.ps-llw {
    position: relative;
    left: 70px;
}

.poo-saw {
    z-index: 60;
}

.sla {
    filter: brightness(0.5);
}

.awe-gg {
    font-size: calc(100% - 10%);
    font-family: Poppins, sans-serif !important;
    font-weight: 500;
}

.aso-aa {
    height: auto;
}

.olls {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 45px;
}

.ldkie {
    display: flex;
    align-items: center;
    height: 10px;
    max-height: 11px;
}

.sas {
    width: calc(100% - 88%);
    border-radius: 50%;
    margin-right: 5px;
}

.awe-gg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(100% - 20%);
}

.ska {
    font-size: calc(100% - 20%);
}

.ool-ppo {
    font-size: calc(100% - 20%);
}

.kk-kks {
    height: 24px;
    margin-left: 3px;
}

.azuki {
    display: none;
}

.sjau-saw {
    display: flex;
    align-items: center;
    justify-content: center;
}

.suj {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sik {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 178px;
    max-width: 170px;
}


.suj {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 178px;
    max-width: 170px;
}

.aaa-ees.sk-aaw.main-page {
    overflow: hidden;
}

.aaa-ees.sla.main-page {
    overflow: hidden;
}

.sas1-ff4g {
    padding: 0px 10px;
}

.as--r44ty {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.saslk-ewee-ff4 {
    width: 100%;
}

.sas-dsd44--6 {
    width: 20%;
    font-size: 0.89rem;
}

.sas-f11-re {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.89rem;
}

.saslk-ewe-u44i {
    width: 14%;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.sas-rty {
    width: 100%;
}

.awe-trty {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.sal-wwe.collection-page {
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    background: #ffffff;
}

.skl {
    width: 100%;
}

.saslas-re-tdsd {
    padding: 10px;
}

.sask-wwer {
    height: 55px;
    padding-bottom: 5px;
    display: flex;
    align-items: flex-end;
}