svg.svg-inline--fa.fa-bars.icon-navbar {
    display: none;
}

.navbar-uls.show-li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    max-height: 100px;
    position: absolute;
    right: 10px;
}


.navbar-ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbar-uls {
    display: none;
    position: relative;
    top: 50px;
    width: 150px;
    border-radius: 20px;
    background: hsl(0 0% 100% / 0.8);
    -webkit-backdrop-filter: blur(0.5rem);
    backdrop-filter: blur(0.5rem);
    padding: 10px;
}

@media(max-width: 750px) {

    .ikss-jnn {
        padding: 10px;
        width: 100%;
    }


    .nh-sh {
        display: none;
    }

    .ijja-ww {
        display: block;
    }

    .slo-aswe {
        display: none
    }

    svg.svg-inline--fa.fa-bars.icon-navbar {
        display: block;
    }

    .skei-dd {
        padding: 0px 15px;
    }

    .nh-sh.sa-hg {
        position: absolute;
        top: 40px;
        z-index: 60;
        background: #e7e7e7;
        padding: 20px;
        right: 80px;
        border-radius: 15px;
        display: block;
    }

    .saj-rlk {
        display: none;
    }

    .ksaw {
        width: calc(100% - 10%);
    }

    .box-logo {
        padding: 5px 15px;
        padding-left: 20px;
        width: 100%;
    }

    .slik-saw {
        flex-direction: column;
    }

    .sjkj-awew {
        display: none;
    }

    .sip-eww {
        flex-direction: column;
        height: auto;
    }

    @keyframes typeout {
        from {
            width: 0;
            opacity: 0;
        }

        to {
            width: 100%;
            opacity: 1;
        }
    }

    .saki-sawee {
        font-size: 15px;
    }

    .icon-navbar {
        cursor: pointer;
    }

    .navbar-ul {
        display: none;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        background: hsl(0 0% 100% / 0.5);
        backdrop-filter: blur(0.5rem);
        padding: 10px;
    }

    .sjau-saw {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .awe-trty {
        width: auto;
        height: auto;
    }

    .aaa-ees.sla.tokenId {
        width: auto;
        height: 320px;
    }

    .isk-llka {
        left: 0px;
        width: auto;
        padding: 0 10px;
    }

    .kjau {
        left: 0;
        width: 100%;
    }

    .saj-rlk {
        bottom: 0;
        right: auto;
        width: inherit;
    }


    .navbar-uls.show-li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100px;
        max-height: 100px;
        position: relative;
        padding: 25px 0px;
    }

    .sas-awertyu {
        left: auto;
        width: auto;
        padding: 10px;
    }

    box-icon {
        width: 20px;
    }

    .ujsi-kjja {
        width: 100%;
        padding: 0px;
    }

    .kaj-lki {
        padding: 20px;
    }

    .kjai {
        padding: 0px;
    }

    .lku-ujy {
        padding: 20px 20px;
    }

    h1.kkjyt {
        font-size: 40px;
    }

    .kjuy-sg {
        max-width: 100%;
        width: 100%;
    }

    .ika-lkk {
        width: 100%;
        padding: 0px;
    }

    .asia-awwe {
        position: absolute;
        width: 150px;
        left: 40px;
        top: calc(100% - 70%);
    }

    .uhgh-hm {
        height: 280px;
    }

    .kajw {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lka {
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        justify-items: center;
        grid-row-gap: 12px;
        grid-column-gap: 12px;
    }

    h2.as-err {
        font-size: calc(100% + 30%);
    }

    .aki-ee {
        height: auto;
    }

    .navbar-uls {
        display: none;
        position: relative;
        top: 10px;
        left: 0;
        width: 600px;
        background: hsl(0 0% 100% / 0.5);
        backdrop-filter: blur(0.5rem);
        padding: 10px;
    }

    .si-ew {
        width: 100%;
    }

    .navbar-ul.show-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 500px;
        max-height: 500px;
    }

    .navbar-md {
        width: 100%;
        justify-content: center;
    }

    .aksi-saw {
        padding: 0;
    }

    .bsa-sle {
        padding: 10px 40px;
    }

    .skek {
        padding: 0px;
    }

    .aslk-fff {
        display: none;
    }

    .sli-sli {
        height: 80px;
        max-height: 90px;
    }

    .sju-aow {
        width: 100%;
    }

    .search-container {
        position: relative;
        right: 30px;
        width: 100%;
    }

    .skai {
        width: calc(100% - 78%);
    }

    .saj-ees {
        width: 40px;
    }

    .boxasw {
        height: 80px;
        max-height: 90px;
    }

    .skdk a {
        height: 80px;
        max-height: 90px;
    }

    .sales-aee {
        display: none;
    }

    .mn-widthC3 {
        display: none;
    }

    .mn-widthC2 {
        display: none;
    }

    .mn-widthC {
        display: none;
    }

    .iksa {
        display: none;
    }

    .iksa-h {
        display: none;
    }

    .aaa-ees {
        width: 180px;
        height: 300px;
    }

    .skek-es {
        font-size: 30px;
    }

    .allw-rt {
        flex-direction: column;
    }

    div.as4er1 {
        flex-direction: column;
        align-items: center;
    }

    div.yjw-s4g {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.asw-ds4 {
        width: 350px;
    }

    div.alsd-fd144td {
        width: 100%;
    }

    div.info-aswerj {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    div.aksj-dfg44h {
        width: 350px;
    }

    div.aksj-dfg44h {
        width: 350px;
    }

    div.aslf1h-gg {
        font-size: 0.9rem;
        width: 60%;
    }

    div.sak-fg11j4 {
        font-size: 0.8rem;
    }

    div.sl4e-fdgf {
        font-size: 1.4rem;
    }

    .aa45-dsju44 {
        flex-direction: column;
    }

    div.lks-dfg1jgff {
        justify-content: center;
    }

    .asjh_Sae {
        width: 250px;
        left: 20%;
    }

    div.box-logo {
        padding-left: 10px;
    }

    div.search-container {
        right: 20px;
    }

    div.navbar-md {
        width: 100%;
    }

    div.s_Dse_tty {
        width: 100%;
    }

    .sasl_ettY_s {
        text-align: center;
        padding-bottom: 15px;
    }

    div.sasw-weeer {
        padding-bottom: 100px;
    }

    .Iu_s414e5.sju_er7-sw.show_sw-yu {
        width: 80%;
        left: 50px;
        top: 8rem;
    }

    .Iu_s414e5.sju_er7-sw {
        position: fixed;
        transition: top 0.5s ease-in-out;
        top: -500px;
        width: 80%;
        left: 50px;
    }

    .sawnnj {
        flex-direction: column;
        align-items: center;
    }

    .saSW_ERRFffD_GFTY.shuwR_RFdfff_YT {
        left: 50px;
        width: 80%;
    }

    .saSW_ERRFffD_GFTY {
        position: fixed;
        bottom: -500px;
        width: 80%;
        background: white;
        left: 50px;
        height: auto;
        border-radius: 5px;
        transition: bottom 0.5s ease-in-out;
    }

}