.dropdown-container {
    position: relative;
}

.dropdown-button {
    background-color: transparent;
    border: none;
    color: #333;
    cursor: pointer;
    font-size: calc(100% + 20%);
    outline: none;
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;

}

.ujs {
    cursor: pointer;
}

.kijs-wwwe {
    text-align: center;
}

.dropdown-menu {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 150px;
    padding: 8px 0;
    z-index: 1;
}

.dropdown-menu a {
    color: #333;
    display: block;
    font-size: 14px;
    padding: 8px 16px;
    text-decoration: none;
}

.dropdown-menu a:hover {
    background-color: #f5f5f5;
}

.lsaw-w {
    background-color: #ffffff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-style: solid;
    border-color: rgb(0, 166, 255);
    border-width: 1px;
}

.lsaw-w:hover:active {
    background-color: #94b4c8;
}