.df4g4g-fdfr {
    display: flex;
    align-items: center;
    justify-content: center;
}

.df4g4g-salle-s {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fragment-sasl-h44 {
    padding: 5px;
}

.dksd4h-hgh4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sas--fdty-yy {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.salsk--frtttyy {
    padding: 5px;
    font-size: calc(100% - 15%);
    font-weight: 600;
}


.saslk-ff44g {
    padding: 5px 10px;
}

.sas-gfg11h {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sasl-rr44t-uu {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.time-elapsed-s445s {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.df4g4g-fdfr {
    background: lightblue;
    border-radius: 10px;
    padding: 5px;
    width: 30%;
}

.df4g4g-salle-s {
    background: rgb(173, 230, 211);
    border-radius: 10px;
    padding: 5px;
    width: 30%;
}

.dksd4h-hgh4 {
    padding: 5px 5px;
}

.df4g4g-fdfr {
    border: solid 0.5px #00c0ff;
}

.df4g4g-salle-s {
    border: solid 0.5px rgb(0, 255, 170);
}

.as-fdtt {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saslk-s444fff {
    display: flex;
    align-items: center;
    justify-content: center;
}

a.sasl-dd4 {
    text-decoration: none;
    color: #242424;
}

a.sasl-dd4:hover {
    text-decoration: underline;
}