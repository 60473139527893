.info-accordion {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;
  background: hsla(0, 0%, 75%, 0.5);
  backdrop-filter: blur(0.5rem);
}

.info {
  padding: 10px;
  text-align: justify;
}

.info-accordion .accordion-header {
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.info-accordion.active .accordion-header {
  background-color: #f0f0f0;
}

.info-accordion .accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.title-header--sas44 {
  padding: 0px 5px;
}

.accordion-header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.info-accordion.active .accordion-content {
  max-height: 300px;
  /* Adjust this value to fit your content */
}