.saSW_ERRFffD_GFTY {
    position: fixed;
    bottom: -500px;
    width: 400px;
    background: white;
    left: 500px;
    height: auto;
    border-radius: 5px;
    transition: bottom 0.5s ease-in-out;
}

.sa_WWerDEDSGHHH_fdf {
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.sasEERRT_GgfN_HJHg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.saW_ERTYhhgf_R {
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    background: gray;
    border-bottom: solid 0.1px;
    font-size: calc(100% + 20%);
    position: absolute;
    right: 0;
}

.sas_WEFFDSsds_Ww {
    text-align: center;
    padding: 20px 0px;
}

.saW_ERRTYffd_DEaD {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}

img.ddsEE_TTgF_S {
    width: 30%;
}

.saSW_ERRFffD_GFTY.shuwR_RFdfff_YT {
    bottom: 200px;
}

.sasW_Errrt_GGfgTYTFD_Ss {
    text-align: center;
    color: green !important;
}

span {
    color: black;
}

.saseee_Rreew {
    color: #000000 !important;
}