.slik-saw {
    height: 450px;
}

.bsa-sle {
    padding: 50px;
}

.sak-w {
    width: calc(100% - 40%);
}

.aksi-saw {
    padding: 20px;
}

.ski-wqaa {
    font-size: calc(100% + 300%);
    margin: 0 0 10px 0;
    white-space: nowrap;
    animation: typeout 3s steps(40, end) forwards;
    font-weight: 400;
    font-family: 'Tilt Warp', cursive;
}

.sip-eww {
    padding: 20px;
    padding-top: 10px;
    font-family: monospace;
    padding: 10px;
    height: 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
}

.skoi-waw {
    font-size: calc(100% + 250%);
    padding: 5px;
    font-weight: 400;
}

.seer-rty {
    padding: 20px;
}

@keyframes typeout {
    from {
        width: 0;
        opacity: 0;
    }

    to {
        width: 40%;
        opacity: 1;
    }
}

.pulse {
    animation: pulse 1s ease-in-out infinite;
}

/* Keyframe animation for the pulse effect */
@keyframes pulse {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
}

.slik-saw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sjkj-awew {
    width: 100%;
}

.sju-aow {
    width: 50%;
}

.skek {
    padding: 20px;
}

.skek-esas {
    text-align: left;
    font-size: calc(100% + 20%);
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    color: #ffffff;
}

.skek-es {
    padding: 10px;
    font-size: calc(100% + 10%);
    font-weight: 500;
}

.sasl-444gtt-t44 {
    background: rgb(53, 53, 53);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    transition: background 0.5s ease;
    font-weight: 600;
    color: white;
}

.sasl-444gtt-t45{
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    transition: background 0.5s ease;
    font-weight: 600;
    color: black;
}

a.sas-dsdert-gg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.sasl-444gtt-t44:hover {
    background: white;
    color: black;
}

.sasl-444gtt-t45:hover {
    background: rgb(53, 53, 53);
    color: rgb(255, 255, 255);

}

.saskldsd--dsde {
    width: 100px;
}

.saslk--dsd4544fff {
    position: relative;
    left: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.button-sassals--44k {
    padding: 0px 10px;
}

.sasl_ettY_s {
    padding: 0px 20px;
    font-size: 2.8rem;
    color: white;
}