.box-container-skjd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

a.content-skajt-s {
    text-decoration: none;
    color: black;
}

a.content-skajt-s:hover {
    text-decoration: underline;
}