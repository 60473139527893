.lak-sawe {
    width: 100%;
    border-radius: 20px;
}

.kk-awweryt {
    padding: 3px;
    background: white;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}