.sawhh-y {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 60;
    top: 150px;
    background-color: #f2f2f2;
    width: 220px;
    max-width: 225px;
    border-radius: 20px;
}

.soakkaw {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
}

.akakaj {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px;
}

.sae {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1px 5px;
}

.slk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    left: 20px;
}

.slk img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.slk {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
}

.sswer {
    display: flex;
    align-items: center;
    width: 160px;
    max-width: 220px;
}

.sasyy {
    padding: 0px 5px;

}

.kfkfk {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sweyuu {
    padding: 5px;
    cursor: pointer;
    font-weight: bold;
}

.sweyuu:hover {
    color: #8e8e8e;

}

.jjusj {
    font-weight: 400;
}

.swerr {
    cursor: pointer;
    font-weight: bolder;
}

.swerr:hover {
    color: #8e8e8e;

}