.sol-sli {
    background: rgb(255, 255, 255);
    height: 10rem;
}

.laik-asay {
    padding: 40px;
}

.sas-ewr {
    color: black;
}

.olas-was {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aso-easa {
    padding: 10px 20px;
    font-size: calc(100% - 10%);
}

.osl-asl {
    padding: 20px;
}

.laik-asay {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}

a.ksk-slai {
    text-decoration: none;
    color: black;
    transition: all 0.5s ease;
}

a.ksk-slai:hover {
    text-decoration: underline;
}