.asw-saawr{
    width: calc(100% - 0%);
    min-width: none;
}

.aki-ee {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}