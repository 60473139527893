.sakis-awerws {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: left;
}

.jjsue-saw {
    padding: 30px 0px;
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;
    font-weight: 700;
}


.mx-width {
    width: calc(100% - 10%);
}

.mn-width {
    width: calc(100% - 89%);
}

.boxasw {
    border-style: solid;
    border-width: 1px;
    padding: 0px 20px;
    border-color: rgb(118, 29, 178);
    border-radius: 20px;
}

.sli-sli {
    padding: 0px 10px;
}

.saj-ees {
    width: calc(100% - 20%);
    border-radius: 10px;
}

.sales-aee {
    width: calc(100% - 85%);
}


.lo-sall {
    width: calc(100% - 80%);
}

.lo-sallh {
    width: calc(100% - 84%);
}

.skai {
    width: calc(100% - 88%);
}

.ski-looi {
    text-decoration: none;
    color: black;
}

.skdk {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.skdk a {
    display: block;
    padding: 10px;
    color: #000000;
    background: hsl(0 0% 100% / 0.5);
    backdrop-filter: blur(0.5rem);
    transition: transform 0.3s ease-in-out;
    border-radius: 20px;
}

.skdk:hover a {
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background: hsl(0 0% 100% / 0.3);
    backdrop-filter: blur(0.5rem);
}

.saki-ss {
    padding-top: 3px;
}

.hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-out;
    padding: 5px;
}


.lskk-sdae {
    font-size: calc(100% + 0%);
    font-weight: 600;
}

.sals {
    display: flex;
    align-items: center;
}

.lskk-sdae {
    padding: 0px 5px;
}

.iksa {
    display: flex;
    align-items: center;
    width: calc(100% - 35%);
}

.sik-swe {
    width: calc(100% - 96.8%);
    padding: 0px 5px;
}

.iksa-h {
    width: calc(100% - 28%);
    display: flex;
    align-items: center;
}