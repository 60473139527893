.allw-rt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.skek-es {
    font-size: calc(100% + 150%);
    font-weight: 500;
    color: rgb(255, 255, 255);
}


.alo-a {
    padding: 40px;
}

.ooi-ooi {
    padding: 30px;
}

.sjkk-ass {
    font-size: 5rem;
    font-family: 'Anton', sans-serif;

}

.skek-es {
    color: white;
}

span.sas--f11hy {
    padding: 5px;
    font-family: 'Anton', sans-serif;
    font-size: 3rem;
}

.sakl-44dse {
    font-size: 1.2rem;
}