.saw-errt {
    width: calc(100% - 73%);
}

.ieks-asw {
    width: 100%;
    height: 100vh;
    background: rgb(91, 94, 148);
    background: linear-gradient(0deg, rgba(91, 94, 148, 1) 0%, rgba(178, 198, 255, 1) 54%, rgba(221, 216, 255, 1) 98%);
    backdrop-filter: blur(0.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
}

.aol-aw {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 250px;
    flex-direction: column;
    background: hsl(0 0% 100% / 0.3);
    backdrop-filter: blur(0.5rem);
    border-radius: 20px;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
}

.sak-sewr {
    font-size: calc(100% + 30%);
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;
    font-weight: 700;
}