.asia-awwe {
    position: absolute;
    width: 150px;
    left: 3rem;
    top: 20rem;
}

.as-err {
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;
    font-size: calc(100% + 80%);
    font-weight: 700;
    padding: 0px 5px;
}

.asw-ert {
    padding-top: 50px;
}

.sas-awertyu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 25%);
}

.sas-awertyu {
    position: relative;
    top: 1rem;
    left: calc(100% - 80%);
    padding: 20px 0px;
}

.saw-kjsu {
    width: 100%;
}

.aswtt-re {
    display: flex;
    align-items: center;
    justify-content: left;
}

.skei-dd {
    display: flex;
    align-items: center;
    border-right: 1px gray solid;
}

.lksi {
    padding: 0px 5px;
}

.lskkww {
    width: 25px;
    height: 25px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-saw {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip {
    position: absolute;
    transform: translate(-50%, -100%);
    top: 0;
    left: 50%;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.ik-swu {
    padding: 20px;
}

.kjau {
    position: relative;
    top: 40px;
    width: calc(100% - 30%);
    max-width: 580px;
    left: 220px;
}

.awwt {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: auto;
    height: auto;
    max-height: 160px;
}

.iky-syj {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

svg.svg-inline--fa.fa-toggle-off {
    padding: 0px 5px;
    width: 30px;
    font-size: calc(100% + 40%);
    font-weight: 400;
    cursor: pointer;
    color: rgb(107, 178, 211);
}

svg.svg-inline--fa.fa-toggle-on {
    padding: 0px 5px;
    width: 30px;
    font-size: calc(100% + 40%);
    font-weight: 400;
    cursor: pointer;
    color: rgb(107, 178, 211);
}

.ksaw {
    display: flex;
    align-items: center;
    width: calc(100% - 10%);
}

.dse {
    font-size: calc(100% + 5%);
}

.iki-uju {
    padding: 5px 10px;
    border-bottom: solid 0.5px white;
}

.lkks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    max-width: 150px;
}

.ass {
    padding: 5px;
}

svg.svg-inline--fa.fa-toggle-off {
    padding: 0px 5px;
    width: 30px;
    font-size: calc(100% + 40%);
    font-weight: 400;
    cursor: pointer;
    color: black;
}


/* Responsive Design CollPage */
@media (max-width: 750px) {
    .asia-awwe {
        position: absolute;
        width: 150px;
        left: 40px;
        top: 10rem;
    }
}