.dropdown-container {
    position: relative;
}

.dropdown-button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.dropdown-menu1 {
    position: absolute;
    top: 100%;
    right: 0;
    width: 220px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
}

.dropdown-url {
    flex-grow: 1;
    margin-right: 10px;
}

.copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #fff;
    color: #333;
    cursor: pointer;
    font-size: 14px;
}

.copy-button:hover {
    background: #f9f9f9;
}

.copy-button:active {
    background: #eee;
}

.dropdown-url {
    font-size: calc(100% - 10%);
}