.sjss-suju {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.osl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    width: calc(100% - 80%);
}

.lkk-kkj {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sjss-sujus {
    width: calc(100% - 80%);
}

.liks-ss {
    padding: 5px 2px;
}

.liks-ss {
    font-size: calc(100% - 10%);
}

.isk-llka {
    background: #ffffff;
    backdrop-filter: blur(0.3rem);
    width: calc(100% - 50%);
    border-radius: 10px;
    position: relative;
    left: 220px;
    top: 30px;
}