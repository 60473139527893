.sla-eer {
    font-size: calc(100% + 80%);
    font-family: 'Anton', sans-serif;
    text-align: center;
}

.ska-wwe {
    padding: 50px;
    padding-bottom: 10px;
}

.skaik-loid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.salsi-aswee {
    padding: 20px 40px;
}


.text-left {
    text-align: left;
}

.text-right {
    text-align: left;
}

.mx-widthC {
    width: calc(100% - 70%);
}

.mn-widthC {
    width: calc(100% - 87%);
}

.mn-widthC2 {
    width: calc(100% - 88%);
}

.mn-widthC3 {
    width: calc(100% - 88%);
}

.salski-dsds {
    padding: 40px;
}

.ln-sas {
    border-bottom: 1px solid white;
}

.saki-sawee {
    font-size: calc(100% + 15%);
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;
    font-weight: 700;
}

.sasl-aswee {
    width: calc(100% - 92%);
}

.askewe {
    display: flex;
    align-items: center;
}

.ika-aw {
    padding: 0px 5px;
}