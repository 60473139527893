.aa7w5 {
    position: absolute;
    width: 150px;
    left: 3rem;
    top: 20rem;
}

.\31 cs2 {
    position: relative;
    left: 250px;
    top: 50px;
}

.yh-44sa {
    width: calc(100% - 20%);
}

p.s4a1w {
    font-size: calc(100% - 20%);
}

.\34 s15 {
    display: flex;
    align-items: center;
}

.\34 5ike-r {
    padding: 0px 10px;
    font-size: calc(100% - 20%);
}

svg.svg-inline--fa.fa-copy {
    cursor: pointer;
}

.yh-44sa {
    width: calc(100% - 20%);
    height: 200px;
}

.\34 5fryu {
    padding: 0px 20px;
}

.as54r {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.lask45 {
    padding: 15px 20px;
}

.awe41 {
    position: relative;
    width: calc(100% - 10%);
}

.\37 4a5awertr {
    position: relative;
    width: 100%;
}

.sd45eyt{
    font-size: calc(100% + 50%);
}