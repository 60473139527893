.text-belt {
  width: 100%;
  overflow: hidden;
  font-size: 1.2rem;
}

.text-belt__content {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-belt 20s linear infinite;
}

.text-belt__text {
  padding-right: 20px;
}

.asfdf-44_41gg {
  position: fixed;
  top: 90px;
  width: 100%;
  z-index: 9;
  background: #ffffffad;
  padding: 5px;
}

@keyframes scroll-belt {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}