.lak-ss {
    width: calc(100% - 10%);
    justify-content: flex-start;
    padding: 20px;
}

.kjuy-sg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 50%);
    max-width: 50%;
}

h1.kkjyt {
    font-size: calc(100% + 150%);
}

svg.svg-inline--fa.fa-pencil.kjsi {
    font-size: calc(100% + 80%);
    padding: 0px 10px;
}

.kaj-lki {
    padding: 20px 50px;
}

.ujsi-kjja {
    display: flex;
    align-items: center;
    padding: 20px 50px;
}

.kjai {
    padding: 0px 50px;
}

.ikss-jnn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h1.sset {
    font-size: calc(100% + 50%);
}

.kjsi {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.kkj-sk {
    margin-bottom: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #333333;
    font-size: 16px;
}

input[type="file"] {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 2px solid #ccc;
    font-size: 1rem;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease-in-out;
}

input[type="file"]:focus {
    outline: none;
    border-color: #0077ff;
}


.yhu-ee {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333333;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.yhu-ee:hover {
    background-color: #555555;
}

p.ika-sssr {
    margin-top: 10px;
    font-size: 16px;
    color: #333333;
}

.llklak {
    padding: 0px 20px;
}

.ika-lkk {
    padding: 0px 50px;
    width: calc(100% - 50%);
}

.kk-y-yt {
    padding: 5px 0px;
    font-size: calc(100% - 20%);
}

.lku-ujy {
    padding: 20px 0px;
}

.awe-a4er {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.aa4-ass7 {
    width: calc(100% - 10%);
    max-width: 90%;
}

.\35 5a-e44w {
    padding: 10px;
    width: calc(100% - 80%);
    max-width: 150px;
}

a.slk-sawwr {
    display: flex;
    align-items: center;
    justify-content: center;
}

svg.svg-inline--fa.fa-bars.uja-hh {
    cursor: pointer;
}