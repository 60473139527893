.alk-sawe {
    color: rgb(44, 44, 44);
    font-size: calc(100% + 50%);
}

.iks-ai {
    padding: 10px 20px;
}

.kaju-akju {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
}

.ksu-saj {
    padding-top: 10px;
    padding-bottom: 5px;
}

.ksk-slai{
    padding: 5px 10px;
}