.forbidden {
    height: 100vh;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgb(84, 86, 145) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
}

.aw4-aa1w4{
    width: 400px;
}