.home-md2 {
    padding-bottom: 30px;
}

.home-md {
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(91, 94, 148, 1) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
}

.sser-tytf {
    padding: 20px;
}

.aww-yu {
    padding: 2px;
    border-style: solid;
    border-color: rgb(0, 255, 255);
    border-width: 1px;
    background: rgb(0, 255, 255);
}

.sasw-weeer {
    padding-bottom: 30px;
}

.asli-awet {
    padding-bottom: 40px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(/src/Assets/Images/background-1.png);
    background-position: center bottom;
    background-size: cover;
}

section.iaso-aweer {
    background: rgb(91, 94, 148);
    background: linear-gradient(rgb(184, 186, 238) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);

}