.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-image-container:hover {
    transform: scale(1.02);
    transition: transform 0.2s ease-out;
    cursor: pointer;
}

.carousel-image-container {
    width: 400px;
    height: 400px;
    transition: transform 0.5s ease-out;
}

.carousel-indicator {
    position: relative;
    left: 0;
    bottom: 0;
}

.carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-out;
    /* Add a smooth transition effect */
}

.carousel-indicator {
    font-size: 16px;
    color: white;
    background: hsl(0 0% 100% / 0.3);
    backdrop-filter: blur(0.5rem);
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
}

.carousel-controls {
    display: flex;
    align-items: center;
    height: 300px;
}

.carousel-control {
    background: hsl(0 0% 100% / 0.3);
    backdrop-filter: blur(0.5rem);
    border-style: solid;
    border-color: rgb(0, 217, 255);
    border-width: 1px;
    font-size: 24px;
    padding: 5px 10px;
    margin: 0 10px;
    border-radius: 10px;
}

.carousel-control:hover {
    cursor: pointer;
    background-color: #f2f2f2;
}

.carousel-control.prev:disabled,
.carousel-control.next:disabled {
    display: none;
}

.next {
    position: absolute;
    right: 1px;
}

.prev {
    position: absolute;
    left: 1px;
}

.ska-asl {
    font-size: 1rem;
    color: #151515;
    padding: 10px;
    text-align: center;
    font-family: 'Tilt Warp', cursive;
}

.skall-waw {
    transition: transform 0.25s ease-in-out
}

.djs-sld {
    font-family: 'Tilt Warp', cursive;
    font-size: calc(100% + 20%);
    text-align: center;
    padding: 20px;
}

.carousel-image {
    width: 100%;
    height: auto;
    transition: opacity 1s ease-in-out;
}

.carousel-image.fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.carousel-image.fade-in {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.carousel-controls {
    position: relative;
}

.carousel-control {
    position: absolute;
    top: 70%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    border-style: solid;
    border-color: rgb(0, 217, 255);
}

.carousel-control:hover {
    background: rgba(0, 0, 0, 0.8);
}

.carousel-control.prev {
    left: 0;
}

.carousel-control.next {
    right: 0;
}

.carousel-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-indicator h2 {
    color: black;
    margin: 0;
}

.sjkj-awew {
    width: 100%;
    overflow: hidden;
    background: white;
    border-radius: 15px;
}

.sasdsd-f-dfgg {
    position: relative;
    bottom: 15rem;
}

.s54g-tty {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
}

.sasl-dd44ff {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}

.salk-s44d-rrt {
    padding: 20px;
}

.as44-fdf44 {
    text-decoration: none;
}