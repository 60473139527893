@import url('https://fonts.googleapis.com/css2?family=Tilt+Warp&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.logo-nav {
    width: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.box-md1 {
    background: #ffffff;
    box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
    position: fixed;
    width: 100%;
    z-index: 99;
}

.box-md2 {
    display: flex;
    align-items: center;
}

.box-md3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.box-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 30px;
    width: 18%;
}

a.as-dffg-hrr {
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.navbar-md {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
}

.navbar-ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-li {
    margin: 2px;
    list-style: none;
    cursor: pointer;
    text-align: center;
}

.mini-ul {
    display: block;
}

.msgSucces {
    width: 50%;
}

.a-md-q {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-decoration: none;
    font-size: calc(100% + 3%);
    color: black;
}

.navbar-li {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: calc(100% + 3%);
    color: black;
}

.hms-sas {
    padding: 5px;
}

.home-lsi {
    text-decoration: none;
    font-size: 1.5em;
    font-family: 'Tilt Warp', cursive;
    color: black;
}

.name-sase {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

.switchchain {
    box-shadow: inset 0px 1px 0px 0px #bee2f9;
    background: linear-gradient(to bottom, #63b8ee 5%, #468ccf 100%);
    background-color: #63b8ee;
    border-radius: 6px;
    border: 1px solid #3866a3;
    display: inline-block;
    cursor: pointer;
    color: rgb(41, 29, 71);
    font-size: 15px;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #7cacde;
}

.switchchain:hover {
    background: linear-gradient(to bottom, #468ccf 5%, #63b8ee 100%);
    background-color: #468ccf;
}

.switchchain:active {
    position: relative;
    top: 1px;
}

.sjau-saw {
    background: rgba(174, 174, 174, 0.59);
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-color: #3866a3;
}

li.navbar-li2.li2 {
    transition: background-color 0.2s ease-in-out;
    width: 100%;
    padding: 10px 5px;
}

a.uji-lki {
    font-weight: 600;
}


li.navbar-li2.li2:hover {
    background-color: #dbdbdb;
}

a.uji-lki {
    text-decoration: none;
    color: black;
}

a.iku-ujsw {
    text-decoration: none;
    color: black;
}

.circle-user {
    padding: 0px;
}

.connectWallet {
    box-shadow: inset 0px 1px 0px 0px #bee2f9;
    background: linear-gradient(to bottom, #63b8ee 5%, #468ccf 100%);
    background-color: #63b8ee;
    border-radius: 6px;
    font-weight: bold;
    border: 1px solid #3866a3;
    display: inline-block;
    cursor: pointer;
    color: rgb(41, 29, 71);
    font-size: 15px;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #7cacde;
}

.connectWallet:hover {
    background: linear-gradient(to bottom, #468ccf 5%, #63b8ee 100%);
    background-color: #468ccf;
}

.connectWallet:active {
    position: relative;
    top: 1px;
}

#sjuali {
    font-weight: 600;
}

.skjaw {
    font-size: 0.4em;
    padding: 7px 4px;
    color: red;
}

.slo-aswe {
    font-size: 1.2em;
    font-family: 'Tilt Warp', cursive;
    color: black;
    display: flex;
}

/* Style the search container */
.search-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 40%;
}

/* Style the search input */
.search-container input[type="text"] {
    flex: 1;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f2f2f2;
}

/* Style the search button */
.search-container button[type="submit"] {
    background-color: #3c3c3d;
    border: none;
    color: #ffffff;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left: 5px;
}

/* Style the search button icon */
.search-container button i {
    font-size: 16px;
}

/* Style the search input on focus */
.search-container input[type="text"]:focus {
    outline: none;
    box-shadow: 0 0 5px #3c3c3d;
}

/* Style the search button on hover */
.search-container button:hover {
    background-color: #444444;
}

.sik-skee {
    position: relative;
}

.s_Dse_tty {
    width: 20%;
}

.wallet {
    cursor: pointer;
    width: 45px;
    height: 45px;
}

.buttonWallet {
    width: 30px;
}

svg.svg-inline--fa.fa-wallet {
    width: 30px;
    height: 30px;
}

.sas_rtyty {
    width: 100%;
}

.sert {
    position: absolute;
    bottom: 0;
    transform: translateX(-50%) translateY(100%);
    transition: transform 0.2s ease-in-out;
    text-decoration: none;
}

.sikak-buy:hover {
    color: rgb(106, 146, 216);
}

.sikak-buy {
    color: rgb(53, 82, 131);
}

.suwl-sell {
    color: rgb(154, 33, 33);
}

.suwl-sell:hover {
    color: rgb(236, 67, 67);
}

.ijsw5 {
    transition: background-color 0.2s ease-in-out;
}

a.a-md-q.ijsw5 {
    padding: 15px;
}

a.as-dffg-hrr {
    padding: 15px;
}

ul.mks-w {
    top: 75px;
}

.ss-df4g {
    width: 45px;
    height: 45px;
    max-width: 45px;
    max-height: 45px;
}

.sasl {
    overflow: hidden;
    border-radius: 100%;
    width: 100%;
    height: 100%;
}

/* New Modal */

.asjh_Sae.visible_Se {
    top: 20%;
}

.asjh_Sae {
    top: 100%;
    transition: top 0.5s ease;
    width: 500px;
    height: 400px;
    position: fixed;
    background: rgb(60 60 60);
    border-radius: 10px;
    left: 30%;
}

.What-sae_SWe {
    padding: 20px;
}

.asassa-fdf11df {
    padding-top: 10px;
}

.content_gf1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    border-radius: 10px;
    padding: 5px;
}

.D_rT_Yyyty {
    width: 50px;
}

img.sas_Rasd {
    width: 100%;
}

.sasr_Trf {
    font-size: 1rem;
}

img.sas_Rasd.woe-ff1r {
    width: 70%;
}

.sas_RTF_Yggf {
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
}

.content_gf1 {
    cursor: pointer;
}