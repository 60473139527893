.tokenId {
    filter: none;
}

.ool-siu {
    font-size: calc(100% + 5%);
    padding: 0px 3px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0px;
    width: calc(100% - 30%);
}

.awe-suj {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bold-sl {
    font-weight: bold;
    justify-content: flex-start;
    width: auto;
}

.so-ski {
    display: flex;
    align-items: center;
}

.olls-lsk {
    justify-content: flex-end;
    top: 20px;
}

.iaku {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(100% + 5%);
}

.iksu-kik {
    width: calc(100% - 30%);
}

.awe-trty, .sik-erty {
    display: flex;
    align-items: center;
}