@import url(https://fonts.googleapis.com/css2?family=Tilt+Warp&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Josefin+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dosis&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Public+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
.navbar-li2{
    padding: 10px;
    list-style: none;
    font-weight: 600;
}

.navbar-a2{
    text-decoration: none;
    font-size: calc(100% + 12%);
    color: black;
}

.navbar-a2{
    font-family: 'Bebas Neue', cursive;
}

.navbar-a2:hover{
    color: rgb(88, 88, 88);
}

.navbar-a2{
    font-weight: 300;
}

.navbar-ul2{
    position: fixed;
    background: hsl(0 0% 100% / 0.5);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    border-radius: 10px;
    top: 60px;
    border-style: solid;
    border-color: #3866a3;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    padding: 10px;
    display: none;
}

.li2:hover{
    background: hsla(197, 57%, 41%, 0.5);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    border-radius: 10px;
}

.navbar-ul2_visible{
    visibility: visible;
}

.a-drop:hover,
.dropdown-li:hover .navbar-ul2{
    display: block;
}

.a-drop{
    cursor: pointer;
}

.disable-links{
    cursor: pointer;
}


.logo-nav {
    width: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.box-md1 {
    background: #ffffff;
    box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.75);
    position: fixed;
    width: 100%;
    z-index: 99;
}

.box-md2 {
    display: flex;
    align-items: center;
}

.box-md3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.box-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 30px;
    width: 18%;
}

a.as-dffg-hrr {
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.navbar-md {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
}

.navbar-ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-li {
    margin: 2px;
    list-style: none;
    cursor: pointer;
    text-align: center;
}

.mini-ul {
    display: block;
}

.msgSucces {
    width: 50%;
}

.a-md-q {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-decoration: none;
    font-size: calc(100% + 3%);
    color: black;
}

.navbar-li {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: calc(100% + 3%);
    color: black;
}

.hms-sas {
    padding: 5px;
}

.home-lsi {
    text-decoration: none;
    font-size: 1.5em;
    font-family: 'Tilt Warp', cursive;
    color: black;
}

.name-sase {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

.switchchain {
    box-shadow: inset 0px 1px 0px 0px #bee2f9;
    background: linear-gradient(to bottom, #63b8ee 5%, #468ccf 100%);
    background-color: #63b8ee;
    border-radius: 6px;
    border: 1px solid #3866a3;
    display: inline-block;
    cursor: pointer;
    color: rgb(41, 29, 71);
    font-size: 15px;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #7cacde;
}

.switchchain:hover {
    background: linear-gradient(to bottom, #468ccf 5%, #63b8ee 100%);
    background-color: #468ccf;
}

.switchchain:active {
    position: relative;
    top: 1px;
}

.sjau-saw {
    background: rgba(174, 174, 174, 0.59);
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-color: #3866a3;
}

li.navbar-li2.li2 {
    transition: background-color 0.2s ease-in-out;
    width: 100%;
    padding: 10px 5px;
}

a.uji-lki {
    font-weight: 600;
}


li.navbar-li2.li2:hover {
    background-color: #dbdbdb;
}

a.uji-lki {
    text-decoration: none;
    color: black;
}

a.iku-ujsw {
    text-decoration: none;
    color: black;
}

.circle-user {
    padding: 0px;
}

.connectWallet {
    box-shadow: inset 0px 1px 0px 0px #bee2f9;
    background: linear-gradient(to bottom, #63b8ee 5%, #468ccf 100%);
    background-color: #63b8ee;
    border-radius: 6px;
    font-weight: bold;
    border: 1px solid #3866a3;
    display: inline-block;
    cursor: pointer;
    color: rgb(41, 29, 71);
    font-size: 15px;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #7cacde;
}

.connectWallet:hover {
    background: linear-gradient(to bottom, #468ccf 5%, #63b8ee 100%);
    background-color: #468ccf;
}

.connectWallet:active {
    position: relative;
    top: 1px;
}

#sjuali {
    font-weight: 600;
}

.skjaw {
    font-size: 0.4em;
    padding: 7px 4px;
    color: red;
}

.slo-aswe {
    font-size: 1.2em;
    font-family: 'Tilt Warp', cursive;
    color: black;
    display: flex;
}

/* Style the search container */
.search-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 40%;
}

/* Style the search input */
.search-container input[type="text"] {
    flex: 1 1;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f2f2f2;
}

/* Style the search button */
.search-container button[type="submit"] {
    background-color: #3c3c3d;
    border: none;
    color: #ffffff;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left: 5px;
}

/* Style the search button icon */
.search-container button i {
    font-size: 16px;
}

/* Style the search input on focus */
.search-container input[type="text"]:focus {
    outline: none;
    box-shadow: 0 0 5px #3c3c3d;
}

/* Style the search button on hover */
.search-container button:hover {
    background-color: #444444;
}

.sik-skee {
    position: relative;
}

.s_Dse_tty {
    width: 20%;
}

.wallet {
    cursor: pointer;
    width: 45px;
    height: 45px;
}

.buttonWallet {
    width: 30px;
}

svg.svg-inline--fa.fa-wallet {
    width: 30px;
    height: 30px;
}

.sas_rtyty {
    width: 100%;
}

.sert {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateX(-50%) translateY(100%);
            transform: translateX(-50%) translateY(100%);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    text-decoration: none;
}

.sikak-buy:hover {
    color: rgb(106, 146, 216);
}

.sikak-buy {
    color: rgb(53, 82, 131);
}

.suwl-sell {
    color: rgb(154, 33, 33);
}

.suwl-sell:hover {
    color: rgb(236, 67, 67);
}

.ijsw5 {
    transition: background-color 0.2s ease-in-out;
}

a.a-md-q.ijsw5 {
    padding: 15px;
}

a.as-dffg-hrr {
    padding: 15px;
}

ul.mks-w {
    top: 75px;
}

.ss-df4g {
    width: 45px;
    height: 45px;
    max-width: 45px;
    max-height: 45px;
}

.sasl {
    overflow: hidden;
    border-radius: 100%;
    width: 100%;
    height: 100%;
}

/* New Modal */

.asjh_Sae.visible_Se {
    top: 20%;
}

.asjh_Sae {
    top: 100%;
    transition: top 0.5s ease;
    width: 500px;
    height: 400px;
    position: fixed;
    background: rgb(60 60 60);
    border-radius: 10px;
    left: 30%;
}

.What-sae_SWe {
    padding: 20px;
}

.asassa-fdf11df {
    padding-top: 10px;
}

.content_gf1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    border-radius: 10px;
    padding: 5px;
}

.D_rT_Yyyty {
    width: 50px;
}

img.sas_Rasd {
    width: 100%;
}

.sasr_Trf {
    font-size: 1rem;
}

img.sas_Rasd.woe-ff1r {
    width: 70%;
}

.sas_RTF_Yggf {
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
}

.content_gf1 {
    cursor: pointer;
}
.search-container {
    position: relative;
}

.sika-wer {
    position: absolute;
    top: 42px;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border-top: none;
    border-radius: 5px;
}

.jusl-asd {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.jusl-asd:hover {
    background-color: #f5f5f5;
}
.aa4we5-ools {
    position: fixed;
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(91, 94, 148, 1) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
    -webkit-backdrop-filter: blur(0.7rem);
            backdrop-filter: blur(0.7rem);
    height: 100vh;
    width: 350px;
    right: -500px;
    top: 0px;
    font-family: 'Rubik', sans-serif;
    transition: right 0.3s ease-out;
    font-weight: 600;
    z-index: 999;
}

.saw-amount-dsk {
    font-size: calc(100% - 30%);
    padding: 0px 5px;
}

.sign-out {
    cursor: pointer;
}

.we4t-se {
    display: flex;
    align-items: flex-start;
}

.swe-gf4t {
    text-decoration: none;
    color: black;
}

.a45e-ry5 {
    font-size: calc(100% - 30%);
}

.\31 a5wwe {
    position: relative;
    top: 20px;
    left: 25px;
    font-size: calc(100% + 25%);
}

.\34 see-fd {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.content-box-s41.ks-dkkuer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.aa4we5-ools.show-sidebar {
    right: 0px;
}

.sask-ee {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
}

.full-arr0 {
    font-weight: 500;
    text-align: center;
    padding: 10px;
}

.content-box-s41 {
    padding: 5px 0px;
}

.aa-wrr4f {
    padding: 10px;
    position: relative;
    top: 30px;
    font-size: calc(100% + 20%);
}

.full-arr1 {
    padding: 30px;
}

hr.saslo-f22g {
    color: lightslategray;
}

.s112s {
    padding: 2px 10px;
}

.full-arr2 {
    padding: 10px 5px;
}

.balance-saw4 {
    padding: 0px 5px;
}

.saw-amount {
    padding: 0px 5px;
}

.sas-d444-r4 {
    background: #ffffff;
    border-radius: 10px;
    border: solid 0.01px lightslategray;
    width: 80%;
}

.content-box-s41.we4t-se {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    font-size: 1rem;
}

.sask-rrt {
    padding: 5px;
}

.full-arr2.sign-out {
    display: flex;
    align-items: center;
    justify-content: center;
}

.saskj-e {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    right: 40px;
}

.sasl-d11dsw {
    padding: 5px 10px;
}

.slka-dsd22 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    font-size: 1rem;
}

.sas-ee44-gg44 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.balance-saw4 {
    font-size: 0.8rem;
}

.sask-asaws {
    width: 100%;
    height: auto;
    padding: 10px 0px;
}

.sskk-dsd11-f {
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
}

.as4v45t {
    display: flex;
    align-items: center;
}

.sa-452-ee45 {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
}

img.ssla-41-sas11e {
    width: 100%;
}

.amount-ssd-r1 {
    font-size: 0.9rem;
}

.sask-rr44-uo1oy {
    font-size: 0.8rem;
}

.sasl-fdf44-6645 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.home-md2 {
    padding-bottom: 30px;
}

.home-md {
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(91, 94, 148, 1) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
}

.sser-tytf {
    padding: 20px;
}

.aww-yu {
    padding: 2px;
    border-style: solid;
    border-color: rgb(0, 255, 255);
    border-width: 1px;
    background: rgb(0, 255, 255);
}

.sasw-weeer {
    padding-bottom: 30px;
}

.asli-awet {
    padding-bottom: 40px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(/static/media/background-1.edc8c23c.png);
    background-position: center bottom;
    background-size: cover;
}

section.iaso-aweer {
    background: rgb(91, 94, 148);
    background: linear-gradient(rgb(184, 186, 238) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);

}
.slik-saw {
    height: 450px;
}

.bsa-sle {
    padding: 50px;
}

.sak-w {
    width: calc(100% - 40%);
}

.aksi-saw {
    padding: 20px;
}

.ski-wqaa {
    font-size: calc(100% + 300%);
    margin: 0 0 10px 0;
    white-space: nowrap;
    -webkit-animation: typeout 3s steps(40, end) forwards;
            animation: typeout 3s steps(40, end) forwards;
    font-weight: 400;
    font-family: 'Tilt Warp', cursive;
}

.sip-eww {
    padding: 20px;
    padding-top: 10px;
    font-family: monospace;
    padding: 10px;
    height: 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
}

.skoi-waw {
    font-size: calc(100% + 250%);
    padding: 5px;
    font-weight: 400;
}

.seer-rty {
    padding: 20px;
}

@-webkit-keyframes typeout {
    from {
        width: 0;
        opacity: 0;
    }

    to {
        width: 40%;
        opacity: 1;
    }
}

@keyframes typeout {
    from {
        width: 0;
        opacity: 0;
    }

    to {
        width: 40%;
        opacity: 1;
    }
}

.pulse {
    -webkit-animation: pulse 1s ease-in-out infinite;
            animation: pulse 1s ease-in-out infinite;
}

/* Keyframe animation for the pulse effect */
@-webkit-keyframes pulse {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
}
@keyframes pulse {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
}

.slik-saw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sjkj-awew {
    width: 100%;
}

.sju-aow {
    width: 50%;
}

.skek {
    padding: 20px;
}

.skek-esas {
    text-align: left;
    font-size: calc(100% + 20%);
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    color: #ffffff;
}

.skek-es {
    padding: 10px;
    font-size: calc(100% + 10%);
    font-weight: 500;
}

.sasl-444gtt-t44 {
    background: rgb(53, 53, 53);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    transition: background 0.5s ease;
    font-weight: 600;
    color: white;
}

.sasl-444gtt-t45{
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    transition: background 0.5s ease;
    font-weight: 600;
    color: black;
}

a.sas-dsdert-gg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.sasl-444gtt-t44:hover {
    background: white;
    color: black;
}

.sasl-444gtt-t45:hover {
    background: rgb(53, 53, 53);
    color: rgb(255, 255, 255);

}

.saskldsd--dsde {
    width: 100px;
}

.saslk--dsd4544fff {
    position: relative;
    left: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.button-sassals--44k {
    padding: 0px 10px;
}

.sasl_ettY_s {
    padding: 0px 20px;
    font-size: 2.8rem;
    color: white;
}
.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-image-container:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    cursor: pointer;
}

.carousel-image-container {
    width: 400px;
    height: 400px;
    transition: -webkit-transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}

.carousel-indicator {
    position: relative;
    left: 0;
    bottom: 0;
}

.carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: -webkit-transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
    /* Add a smooth transition effect */
}

.carousel-indicator {
    font-size: 16px;
    color: white;
    background: hsl(0 0% 100% / 0.3);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
}

.carousel-controls {
    display: flex;
    align-items: center;
    height: 300px;
}

.carousel-control {
    background: hsl(0 0% 100% / 0.3);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    border-style: solid;
    border-color: rgb(0, 217, 255);
    border-width: 1px;
    font-size: 24px;
    padding: 5px 10px;
    margin: 0 10px;
    border-radius: 10px;
}

.carousel-control:hover {
    cursor: pointer;
    background-color: #f2f2f2;
}

.carousel-control.prev:disabled,
.carousel-control.next:disabled {
    display: none;
}

.next {
    position: absolute;
    right: 1px;
}

.prev {
    position: absolute;
    left: 1px;
}

.ska-asl {
    font-size: 1rem;
    color: #151515;
    padding: 10px;
    text-align: center;
    font-family: 'Tilt Warp', cursive;
}

.skall-waw {
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out
}

.djs-sld {
    font-family: 'Tilt Warp', cursive;
    font-size: calc(100% + 20%);
    text-align: center;
    padding: 20px;
}

.carousel-image {
    width: 100%;
    height: auto;
    transition: opacity 1s ease-in-out;
}

.carousel-image.fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.carousel-image.fade-in {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.carousel-controls {
    position: relative;
}

.carousel-control {
    position: absolute;
    top: 70%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    border-style: solid;
    border-color: rgb(0, 217, 255);
}

.carousel-control:hover {
    background: rgba(0, 0, 0, 0.8);
}

.carousel-control.prev {
    left: 0;
}

.carousel-control.next {
    right: 0;
}

.carousel-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-indicator h2 {
    color: black;
    margin: 0;
}

.sjkj-awew {
    width: 100%;
    overflow: hidden;
    background: white;
    border-radius: 15px;
}

.sasdsd-f-dfgg {
    position: relative;
    bottom: 15rem;
}

.s54g-tty {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
}

.sasl-dd44ff {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}

.salk-s44d-rrt {
    padding: 20px;
}

.as44-fdf44 {
    text-decoration: none;
}
.aaa-ees {
    height: 320px;
    width: 200px;
    background: white;
    border-radius: 20px;
}

.skl {
    width: 100%;
}

.aso-aa {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.sk-aaw {
    height: 350px;
    width: 230px;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
}

.aaa-ees.sla.main-page:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;

}

.aaa-ees.sk-aaw.main-page:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.ps-lle {
    position: relative;
    right: 80px;
    z-index: 50;

}

.as-ssw.ps-llw.token-swew.collection-buddy {
    position: unset;
}

.as-ssw.ps-llw {
    position: relative;
    left: 70px;
}

.poo-saw {
    z-index: 60;
}

.sla {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

.awe-gg {
    font-size: calc(100% - 10%);
    font-family: Poppins, sans-serif !important;
    font-weight: 500;
}

.aso-aa {
    height: auto;
}

.olls {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 45px;
}

.ldkie {
    display: flex;
    align-items: center;
    height: 10px;
    max-height: 11px;
}

.sas {
    width: calc(100% - 88%);
    border-radius: 50%;
    margin-right: 5px;
}

.awe-gg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(100% - 20%);
}

.ska {
    font-size: calc(100% - 20%);
}

.ool-ppo {
    font-size: calc(100% - 20%);
}

.kk-kks {
    height: 24px;
    margin-left: 3px;
}

.azuki {
    display: none;
}

.sjau-saw {
    display: flex;
    align-items: center;
    justify-content: center;
}

.suj {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sik {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 178px;
    max-width: 170px;
}


.suj {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 178px;
    max-width: 170px;
}

.aaa-ees.sk-aaw.main-page {
    overflow: hidden;
}

.aaa-ees.sla.main-page {
    overflow: hidden;
}

.sas1-ff4g {
    padding: 0px 10px;
}

.as--r44ty {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.saslk-ewee-ff4 {
    width: 100%;
}

.sas-dsd44--6 {
    width: 20%;
    font-size: 0.89rem;
}

.sas-f11-re {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.89rem;
}

.saslk-ewe-u44i {
    width: 14%;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.sas-rty {
    width: 100%;
}

.awe-trty {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.sal-wwe.collection-page {
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    background: #ffffff;
}

.skl {
    width: 100%;
}

.saslas-re-tdsd {
    padding: 10px;
}

.sask-wwer {
    height: 55px;
    padding-bottom: 5px;
    display: flex;
    align-items: flex-end;
}
.allw-rt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.skek-es {
    font-size: calc(100% + 150%);
    font-weight: 500;
    color: rgb(255, 255, 255);
}


.alo-a {
    padding: 40px;
}

.ooi-ooi {
    padding: 30px;
}

.sjkk-ass {
    font-size: 5rem;
    font-family: 'Anton', sans-serif;

}

.skek-es {
    color: white;
}

span.sas--f11hy {
    padding: 5px;
    font-family: 'Anton', sans-serif;
    font-size: 3rem;
}

.sakl-44dse {
    font-size: 1.2rem;
}
.alk-sawe {
    color: rgb(44, 44, 44);
    font-size: calc(100% + 50%);
}

.iks-ai {
    padding: 10px 20px;
}

.kaju-akju {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: center;
}

.ksu-saj {
    padding-top: 10px;
    padding-bottom: 5px;
}

.ksk-slai{
    padding: 5px 10px;
}
.sol-sli {
    background: rgb(255, 255, 255);
    height: 10rem;
}

.laik-asay {
    padding: 40px;
}

.sas-ewr {
    color: black;
}

.olas-was {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aso-easa {
    padding: 10px 20px;
    font-size: calc(100% - 10%);
}

.osl-asl {
    padding: 20px;
}

.laik-asay {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}

a.ksk-slai {
    text-decoration: none;
    color: black;
    transition: all 0.5s ease;
}

a.ksk-slai:hover {
    text-decoration: underline;
}
.sakis-awerws {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: left;
}

.jjsue-saw {
    padding: 30px 0px;
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;
    font-weight: 700;
}


.mx-width {
    width: calc(100% - 10%);
}

.mn-width {
    width: calc(100% - 89%);
}

.boxasw {
    border-style: solid;
    border-width: 1px;
    padding: 0px 20px;
    border-color: rgb(118, 29, 178);
    border-radius: 20px;
}

.sli-sli {
    padding: 0px 10px;
}

.saj-ees {
    width: calc(100% - 20%);
    border-radius: 10px;
}

.sales-aee {
    width: calc(100% - 85%);
}


.lo-sall {
    width: calc(100% - 80%);
}

.lo-sallh {
    width: calc(100% - 84%);
}

.skai {
    width: calc(100% - 88%);
}

.ski-looi {
    text-decoration: none;
    color: black;
}

.skdk {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.skdk a {
    display: block;
    padding: 10px;
    color: #000000;
    background: hsl(0 0% 100% / 0.5);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    border-radius: 20px;
}

.skdk:hover a {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background: hsl(0 0% 100% / 0.3);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
}

.saki-ss {
    padding-top: 3px;
}

.hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    padding: 5px;
}


.lskk-sdae {
    font-size: calc(100% + 0%);
    font-weight: 600;
}

.sals {
    display: flex;
    align-items: center;
}

.lskk-sdae {
    padding: 0px 5px;
}

.iksa {
    display: flex;
    align-items: center;
    width: calc(100% - 35%);
}

.sik-swe {
    width: calc(100% - 96.8%);
    padding: 0px 5px;
}

.iksa-h {
    width: calc(100% - 28%);
    display: flex;
    align-items: center;
}
.sla-eer {
    font-size: calc(100% + 80%);
    font-family: 'Anton', sans-serif;
    text-align: center;
}

.ska-wwe {
    padding: 50px;
    padding-bottom: 10px;
}

.skaik-loid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.salsi-aswee {
    padding: 20px 40px;
}


.text-left {
    text-align: left;
}

.text-right {
    text-align: left;
}

.mx-widthC {
    width: calc(100% - 70%);
}

.mn-widthC {
    width: calc(100% - 87%);
}

.mn-widthC2 {
    width: calc(100% - 88%);
}

.mn-widthC3 {
    width: calc(100% - 88%);
}

.salski-dsds {
    padding: 40px;
}

.ln-sas {
    border-bottom: 1px solid white;
}

.saki-sawee {
    font-size: calc(100% + 15%);
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;
    font-weight: 700;
}

.sasl-aswee {
    width: calc(100% - 92%);
}

.askewe {
    display: flex;
    align-items: center;
}

.ika-aw {
    padding: 0px 5px;
}
.text-belt {
  width: 100%;
  overflow: hidden;
  font-size: 1.2rem;
}

.text-belt__content {
  display: inline-block;
  white-space: nowrap;
  -webkit-animation: scroll-belt 20s linear infinite;
          animation: scroll-belt 20s linear infinite;
}

.text-belt__text {
  padding-right: 20px;
}

.asfdf-44_41gg {
  position: fixed;
  top: 90px;
  width: 100%;
  z-index: 9;
  background: #ffffffad;
  padding: 5px;
}

@-webkit-keyframes scroll-belt {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes scroll-belt {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
.asw-saawr{
    width: calc(100% - 0%);
    min-width: none;
}

.aki-ee {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.lak-sawe {
    width: 100%;
    border-radius: 20px;
}

.kk-awweryt {
    padding: 3px;
    background: white;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.asia-awwe {
    position: absolute;
    width: 150px;
    left: 3rem;
    top: 20rem;
}

.as-err {
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;
    font-size: calc(100% + 80%);
    font-weight: 700;
    padding: 0px 5px;
}

.asw-ert {
    padding-top: 50px;
}

.sas-awertyu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 25%);
}

.sas-awertyu {
    position: relative;
    top: 1rem;
    left: calc(100% - 80%);
    padding: 20px 0px;
}

.saw-kjsu {
    width: 100%;
}

.aswtt-re {
    display: flex;
    align-items: center;
    justify-content: left;
}

.skei-dd {
    display: flex;
    align-items: center;
    border-right: 1px gray solid;
}

.lksi {
    padding: 0px 5px;
}

.lskkww {
    width: 25px;
    height: 25px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-saw {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip {
    position: absolute;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    top: 0;
    left: 50%;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.ik-swu {
    padding: 20px;
}

.kjau {
    position: relative;
    top: 40px;
    width: calc(100% - 30%);
    max-width: 580px;
    left: 220px;
}

.awwt {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: auto;
    height: auto;
    max-height: 160px;
}

.iky-syj {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

svg.svg-inline--fa.fa-toggle-off {
    padding: 0px 5px;
    width: 30px;
    font-size: calc(100% + 40%);
    font-weight: 400;
    cursor: pointer;
    color: rgb(107, 178, 211);
}

svg.svg-inline--fa.fa-toggle-on {
    padding: 0px 5px;
    width: 30px;
    font-size: calc(100% + 40%);
    font-weight: 400;
    cursor: pointer;
    color: rgb(107, 178, 211);
}

.ksaw {
    display: flex;
    align-items: center;
    width: calc(100% - 10%);
}

.dse {
    font-size: calc(100% + 5%);
}

.iki-uju {
    padding: 5px 10px;
    border-bottom: solid 0.5px white;
}

.lkks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    max-width: 150px;
}

.ass {
    padding: 5px;
}

svg.svg-inline--fa.fa-toggle-off {
    padding: 0px 5px;
    width: 30px;
    font-size: calc(100% + 40%);
    font-weight: 400;
    cursor: pointer;
    color: black;
}


/* Responsive Design CollPage */
@media (max-width: 750px) {
    .asia-awwe {
        position: absolute;
        width: 150px;
        left: 40px;
        top: 10rem;
    }
}
.dropdown-container {
    position: relative;
}

.dropdown-button {
    background-color: transparent;
    border: none;
    color: #333;
    cursor: pointer;
    font-size: calc(100% + 20%);
    outline: none;
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;

}

.ujs {
    cursor: pointer;
}

.kijs-wwwe {
    text-align: center;
}

.dropdown-menu {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 150px;
    padding: 8px 0;
    z-index: 1;
}

.dropdown-menu a {
    color: #333;
    display: block;
    font-size: 14px;
    padding: 8px 16px;
    text-decoration: none;
}

.dropdown-menu a:hover {
    background-color: #f5f5f5;
}

.lsaw-w {
    background-color: #ffffff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-style: solid;
    border-color: rgb(0, 166, 255);
    border-width: 1px;
}

.lsaw-w:hover:active {
    background-color: #94b4c8;
}
.dropdown-container {
    position: relative;
}

.dropdown-button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.dropdown-menu1 {
    position: absolute;
    top: 100%;
    right: 0;
    width: 220px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
}

.dropdown-url {
    flex-grow: 1;
    margin-right: 10px;
}

.copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #fff;
    color: #333;
    cursor: pointer;
    font-size: 14px;
}

.copy-button:hover {
    background: #f9f9f9;
}

.copy-button:active {
    background: #eee;
}

.dropdown-url {
    font-size: calc(100% - 10%);
}
svg.svg-inline--fa.fa-star.yellow {
    color: #0087FF;
}

.favorite-button {
    cursor: pointer;
}
.sjss-suju {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.osl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    width: calc(100% - 80%);
}

.lkk-kkj {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sjss-sujus {
    width: calc(100% - 80%);
}

.liks-ss {
    padding: 5px 2px;
}

.liks-ss {
    font-size: calc(100% - 10%);
}

.isk-llka {
    background: #ffffff;
    -webkit-backdrop-filter: blur(0.3rem);
            backdrop-filter: blur(0.3rem);
    width: calc(100% - 50%);
    border-radius: 10px;
    position: relative;
    left: 220px;
    top: 30px;
}
.aaa-ees {
    height: 320px;
    width: 200px;
    background: white;
    border-radius: 20px;
}

.skl {
    width: 100%;
}

.aso-aa {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.sk-aaw {
    height: 350px;
    width: 230px;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
}

.aaa-ees.sla.main-page:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;

}

.aaa-ees.sk-aaw.main-page:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.ps-lle {
    position: relative;
    right: 80px;
    z-index: 50;

}

.as-ssw.ps-llw.token-swew.collection-buddy {
    position: unset;
}

.as-ssw.ps-llw {
    position: relative;
    left: 70px;
}

.poo-saw {
    z-index: 60;
}

.sla {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

.awe-gg {
    font-size: calc(100% - 10%);
    font-family: Poppins, sans-serif !important;
    font-weight: 500;
}

.aso-aa {
    height: auto;
}

.olls {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 45px;
}

.ldkie {
    display: flex;
    align-items: center;
    height: 10px;
    max-height: 11px;
}

.sas {
    width: calc(100% - 88%);
    border-radius: 50%;
    margin-right: 5px;
}

.awe-gg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(100% - 20%);
}

.ska {
    font-size: calc(100% - 20%);
}

.ool-ppo {
    font-size: calc(100% - 20%);
}

.kk-kks {
    height: 24px;
    margin-left: 3px;
}

.azuki {
    display: none;
}

.sjau-saw {
    display: flex;
    align-items: center;
    justify-content: center;
}

.suj {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sik {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 178px;
    max-width: 170px;
}


.suj {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.aaa-ees.sk-aaw.main-page {
    overflow: hidden;
}

.skl.loadGif {
    width: 100%;
    height: 100%;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.aaa-ees.sla.main-page {
    overflow: hidden;
}

.sas1-ff4g {
    padding: 0px 10px;
}

.as--r44ty {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.saslk-ewee-ff4 {
    width: 100%;
}

.sas-dsd44--6 {
    width: 20%;
    font-size: 0.89rem;
}

.sas-f11-re {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.89rem;
}

.saslk-ewe-u44i {
    width: 14%;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.sas-rty {
    width: 100%;
}

.awe-trty {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.sal-wwe.collection-page {
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    background: #ffffff;
}

.skl.iksa_WE {
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    width: 100%;
}

.saslas-re-tdsd {
    padding: 10px;
    width: 15rem;
}

.sask-wwer {
    height: 55px;
    padding-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

img.skl:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.tokenId {
    -webkit-filter: none;
            filter: none;
}

.ool-siu {
    font-size: calc(100% + 5%);
    padding: 0px 3px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0px;
    width: calc(100% - 30%);
}

.awe-suj {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bold-sl {
    font-weight: bold;
    justify-content: flex-start;
    width: auto;
}

.so-ski {
    display: flex;
    align-items: center;
}

.olls-lsk {
    justify-content: flex-end;
    top: 20px;
}

.iaku {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(100% + 5%);
}

.iksu-kik {
    width: calc(100% - 30%);
}

.awe-trty, .sik-erty {
    display: flex;
    align-items: center;
}
.lka {
    flex-wrap: wrap;
    width: 100%;
}

a.jhsy-aa {
    background: black;
    color: white;
    text-decoration: none;
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
}

a.jhsy-aa:hover {
    background-color: #555555;
}

.akke-rr {
    position: fixed;
    right: 20px;
    top: 100px;
}

.nh-sh {
    display: flex;
    align-items: center;
    justify-content: center;
}

.jusl-asd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.kk-ssqw {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.kjak-hy {
    padding: 10px;
}

.ikss-jnn {
    width: calc(100% - 50%);
    height: auto;
}

.ikj-saaw {
    padding: 5px;
}

.oska-sww {
    padding: 0px 10px;
}

.aaw-sff {
    padding: 0px 15px;
    margin-bottom: 10px;
}

.asa-eee.percentageData {
    width: 70px;
    padding: 0 10px;
}

.asa-eee.wallet {
    width: 420px;
    padding: 0 10px;

}

.iksa-kjsu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: -webkit-fill-available;
}

.asw-rtu {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eth-address {
    width: 100%
}

.percentage {
    width: 100%;
}


textarea.kkj-sk {
    height: 100%;
    width: 100%;
    background: white;
    border: 1px solid;
}

img.sikaj {
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.iku-ujsw {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ijja-ww {
    display: none;
}

.lka,
.saj-rlk {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    grid-row-gap: 15px;
    grid-column-gap: 15px;
}

.saj-rlk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sik-erty {
    flex-wrap: wrap;
    justify-content: right;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding: 10px;
}

.jj-awe {
    transition: scale 0.4s ease;
}

.jj-awe:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.saj-rlk {
    border-radius: 10px;
}

.sasol {
    width: 120px;
    max-width: 170px;
}

.sawnnj {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.kjs-sjue {
    text-decoration: none;
    color: black;
}

.slakk-lk {
    position: relative;
    bottom: 785px;
    z-index: 60;
    text-align: center;
    cursor: pointer;
    color: #6a61ff;
    font-weight: bold;
    background: #ffffff73;
}

.sik-erty {
    border-left: solid 0.5px white;
}

.sawnnj {
    border-top: solid 0.5px white;
}

.saj-rlk.sujuk_ERTh-d {
    grid-row-gap: 5px;
}

button.as_ERTffD_s {
    width: 100%;
    border: none;
    background: #9696e1;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background 0.5s ease-in-out;
}

button.as_ERTffD_s:hover {
    background: #bbbbf0;
}

div.s_WrrDS_eRT_D {
    position: absolute;
    transition: bottom 0.5s ease-in-out 0s;
    bottom: -50px;
    right: 0;
    left: 0;
}

div.s_WrrDS_eRT_D.showBuyButton_S {
    bottom: 0px;
}

.S_Wrr_TY_de_R {
    padding: 15px;
}

.aS_Wee_RTgfgfd_D {
    padding: 5px;
}

.iksu-kik {
    padding: 10px 5px;
}

.ass-aawwe {
    padding: 5px;
}

.aso-aa {
    overflow: hidden;
    border-radius: 10px;
}

.sas_WWertt {
    font-size: calc(100% - 10%);
}

.sujuk_ERTh-d_DF {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    grid-row-gap: 15px;
    grid-column-gap: 15px;
}


/* 
* Responsive design 
*/
.sawhh-y {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 60;
    top: 150px;
    background-color: #f2f2f2;
    width: 220px;
    max-width: 225px;
    border-radius: 20px;
}

.soakkaw {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
}

.akakaj {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px;
}

.sae {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1px 5px;
}

.slk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    left: 20px;
}

.slk img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.slk {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
}

.sswer {
    display: flex;
    align-items: center;
    width: 160px;
    max-width: 220px;
}

.sasyy {
    padding: 0px 5px;

}

.kfkfk {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sweyuu {
    padding: 5px;
    cursor: pointer;
    font-weight: bold;
}

.sweyuu:hover {
    color: #8e8e8e;

}

.jjusj {
    font-weight: 400;
}

.swerr {
    cursor: pointer;
    font-weight: bolder;
}

.swerr:hover {
    color: #8e8e8e;

}
.ika-ww {
    cursor: pointer;
    font-size: calc(100% - 30%);
}

.ika-ww:hover {
    color: #454545;
    text-decoration: underline;
}
.lak-ss {
    width: calc(100% - 10%);
    justify-content: flex-start;
    padding: 20px;
}

.kjuy-sg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 50%);
    max-width: 50%;
}

h1.kkjyt {
    font-size: calc(100% + 150%);
}

svg.svg-inline--fa.fa-pencil.kjsi {
    font-size: calc(100% + 80%);
    padding: 0px 10px;
}

.kaj-lki {
    padding: 20px 50px;
}

.ujsi-kjja {
    display: flex;
    align-items: center;
    padding: 20px 50px;
}

.kjai {
    padding: 0px 50px;
}

.ikss-jnn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h1.sset {
    font-size: calc(100% + 50%);
}

.kjsi {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.kkj-sk {
    margin-bottom: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #333333;
    font-size: 16px;
}

input[type="file"] {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 2px solid #ccc;
    font-size: 1rem;
    color: #555;
    background-color: #fff;
    transition: border-color 0.3s ease-in-out;
}

input[type="file"]:focus {
    outline: none;
    border-color: #0077ff;
}


.yhu-ee {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333333;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.yhu-ee:hover {
    background-color: #555555;
}

p.ika-sssr {
    margin-top: 10px;
    font-size: 16px;
    color: #333333;
}

.llklak {
    padding: 0px 20px;
}

.ika-lkk {
    padding: 0px 50px;
    width: calc(100% - 50%);
}

.kk-y-yt {
    padding: 5px 0px;
    font-size: calc(100% - 20%);
}

.lku-ujy {
    padding: 20px 0px;
}

.awe-a4er {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.aa4-ass7 {
    width: calc(100% - 10%);
    max-width: 90%;
}

.\35 5a-e44w {
    padding: 10px;
    width: calc(100% - 80%);
    max-width: 150px;
}

a.slk-sawwr {
    display: flex;
    align-items: center;
    justify-content: center;
}

svg.svg-inline--fa.fa-bars.uja-hh {
    cursor: pointer;
}
.forbidden {
    height: 100vh;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgb(84, 86, 145) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
}

.aw4-aa1w4 {
    width: 400px;
}
.aa7w5 {
    position: absolute;
    width: 150px;
    left: 3rem;
    top: 20rem;
}

.\31 cs2 {
    position: relative;
    left: 250px;
    top: 50px;
}

.yh-44sa {
    width: calc(100% - 20%);
}

p.s4a1w {
    font-size: calc(100% - 20%);
}

.\34 s15 {
    display: flex;
    align-items: center;
}

.\34 5ike-r {
    padding: 0px 10px;
    font-size: calc(100% - 20%);
}

svg.svg-inline--fa.fa-copy {
    cursor: pointer;
}

.yh-44sa {
    width: calc(100% - 20%);
    height: 200px;
}

.\34 5fryu {
    padding: 0px 20px;
}

.as54r {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.lask45 {
    padding: 15px 20px;
}

.awe41 {
    position: relative;
    width: calc(100% - 10%);
}

.\37 4a5awertr {
    position: relative;
    width: 100%;
}

.sd45eyt{
    font-size: calc(100% + 50%);
}
.ss5rr {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(5, minmax(0px, 1fr));
    grid-row-gap: 15px;
    grid-column-gap: 15px;
}
.aty55 {
    padding: 10px 5px;
}

a.dfyy5i {
    color: black;
    text-decoration: none;
}

a.ol4a5 {
    color: black;
    text-decoration: none;
    text-align: center;
}

.\37 4a5awertr {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.\37 sw4 {
    width: calc(100% - 5%);
    border-radius: 20px;
}

.\37 sw4:hover {
    background: rgb(255, 255, 255);

}

.as4a1e-tty {
    padding: 20px;
    position: relative;
    bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(235, 235, 235);
    border-radius: 20px;
    justify-content: center;
}

.as54r {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.jawe-nfts-we {
    width: 100%;
    height: 100%;
}

.asw-ds4 {
    width: 450px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    height: auto;
    max-width: 450px;
    transition: scale 0.5s ease-out;
}

.asw-ds5 {
    width: 450px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    height: 450px;
    max-width: 450px;
    transition: scale 0.5s ease-out;
}

.asw-ds4:hover {
    scale: 1.05;
}

.asw-ds5:hover {
    scale: 1.05;
}

.jawe-nfts-we2 {
    width: calc(100% - 70%);
}

.iks-lk4 {
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(91, 94, 148, 1) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);

}

.as4er1 {
    padding: 50px;

}

.asart-box-sw1:hover,
.asart-box-sw2:hover {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.aas1e4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.a-ffg4d {
    padding: 10px;
}

.aks-rtyui4 {
    background: #b8d3d9;
    padding: 15px;
    border-radius: 10px;
    width: 100px;
    text-align: center;
    font-size: calc(100% - 20%);
    overflow: hidden;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: background 0.2s ease;
}

a.d4s4ryu5 {
    color: gray;
    text-decoration: none;
}

.ss4e5rt {
    width: 450px;
    max-width: 450px;
}

.a45ert-hft {
    width: 450px;
    max-width: 450px;
}

.aa1etu-uhgg {
    padding: 5px;
}

.content-data-slke4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}

.as4rl4 {
    padding: 20px 10px;
}

.aaae4y {
    background: white;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
}

.as4er1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.as144ds {
    padding: 0px 20px;
}

.sase-dff4h {
    padding: 0px 5px;
}

.\35 s44tyi {
    padding: 10px 0px;
}

.a0ert1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.\31 a45e-hhf {
    width: calc(100% - 10%);
    text-align: center;
}

.ska {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calc(100% - 10%);
    padding: 5px 0px;
}

.aw1ruh-fd {
    width: 100%;
    text-align: center;
}

.name-token-history {
    padding: 5px;
}

.ska {
    padding: 5px;
}

.a-sleiik {
    text-decoration: none;
    color: black;
}

.a1d4h-kji {
    background: white;
    border-radius: 20px;
    padding: 10px;
    overflow: scroll;
    height: 360px;
    max-height: 360px;
}

.ask-ersae-t {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.akas-rtg4 {
    width: 100%;
    padding-bottom: 20px;
}

.sdsfj-fdsss {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sja4fg5 {
    padding: 5px;
}

.aks-rtyui4:hover {
    background: #dbf8ff;
}

.sak45h.key {
    font-size: 0.95rem;
    font-weight: 500;
    padding: 5px 0px;
    color: #000000 !important;
}
.fg14k-lj4h {
    background: white;
    height: auto;
    border-radius: 20px;
}

.alsd-fd144td {
    width: 50%;
    padding: 0px 30px;
}


.as41-tty45f {
    width: 100%;
    max-width: 100%;
}

.sas154e-gf5 {
    width: 45px;
    max-width: 45px;
    height: 45px;
    max-height: 45px;
    overflow: hidden;
    border-radius: 50%;
    transition: scale 0.5s ease-in-out;
}

.sas154e-gf5:hover {
    scale: 1.1;
}

.sl4e-fdgf {
    font-weight: 800;
    font-family: 'Public Sans', sans-serif;
    font-size: calc(100% + 60%);
}

.minter-ska45s {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.tokenInfo-sss1d52 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.slk-s1d565 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.crrOwner-asel-f14 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.adkfg-gfd55 {
    font-weight: 700;
    color: rgb(126, 126, 126);
    font-size: calc(100% - 20%);
    padding: 10px;
}

.sajr1s-d {
    font-weight: 500;
    color: black;
    font-size: calc(100% + 40%);
    text-decoration: none;
}

.as22f5-fds {
    padding: 0px;
    font-size: calc(100% + 50%);
}

.s11fgh-dft {
    padding: 30px;
}

.kks-rty1u-fg1 {
    font-size: calc(100% - 10%);
    padding: 15px 5px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

.kks-rty1u {
    font-size: calc(100% + 50%);
    padding: 15px 0px;
    display: flex;
    align-items: center;
}

.lks-dfg1jgff {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: black;
    width: 100%;
}

.ksn-er1j4 {
    padding: 20px;
}

.sas44d-fgh {
    font-size: calc(100% - 10%);
    color: rgb(88, 88, 88);
}

.lask1s-fd44 {
    background: hsla(0, 0%, 84%, 0.8);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    padding: 20px;
    border-radius: 20px;
}

.info-aswerj {
    padding: 15px 0px;
}

.as41-tty45f {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ska-sas-gt {
    overflow: hidden;
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

img.sas-rytu4 {
    width: 100%;
}

.name-saler {
    padding: 10px;
    font-size: calc(100% + 10%);
    font-weight: 600;
    text-decoration: none;
    color: black;
}

.sakj--dsdre {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 20px;
}

.sas-lrrt4 {
    text-decoration: none;
}

.aa45-dsju44 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button-abyue-1s1 {
    width: 100%;
    padding: 5px;
}

button.sal11ff-rrt4 {
    width: 60%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: calc(100% + 20%);
    transition: background 0.2s ease;
}

button.sal11ff-rrt4:hover {
    background: white;
}

.ask-er44y-s {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sak-platform-sas5 {
    width: 90px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

img.platform-sas44f {
    width: 100%;
}

.askk-saw1ds {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-buttons-asfg41 {
    width: 100%;
}

.sal11ff-rrt4-54 {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    width: 80%;
    background: #5c5c5c;
    color: white;
    transition: background 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sal11ff-rrt4-54:hover {
    background: #8d8d8d;
}

.sask--154hg {
    padding-bottom: 20px;
}

.sak-fg11j4 {
    padding: 0px 5px;
}

.ssfd45-gfgt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aslf1h-gg {
    font-size: calc(100% + 30%);
    padding: 10px 0px;
    color: black;
    width: 100%;
}

.sask-ffdf {
    padding: 5px;
    width: 20%;
}

.box-buttons-asfg41 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.das-sshy {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: calc(100% + 20%);
    transition: background 0.2s ease;
}

.das-sshy:hover {
    background: white;
}

.a5a5ee {
    width: 450px;
}

.bid-sawe155gd-sdrt {
    padding: 20px 0px;
}

.ask--fdsasf {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

svg.svg-inline--fa.fa-users {
    width: 80%;
    height: 80%;
}

.saskFf-f {
    font-size: calc(100% + 30%);
}

.sask--154hg {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.three-points {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

span.asas-r4r4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1px;
    height: 7px;
    max-height: 15px;
    font-size: 2rem;
}

.three-points {
    width: 15px;
    height: 15px;
    align-items: center;
    cursor: pointer;
}

.sasdsdE_TTTygss_WW {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sask_Etty_Ths {
    padding: 0px 5px;
}

/* ss-see */

.as_Erdas {
    display: flex;
    flex-direction: column;
}

input.sh_Err-teew.stye_sew1-sw {
    padding: 8px;
    border-radius: 5px;
    border: none;
    background: #f2f2f2;
    width: 100%;
    box-sizing: border-box;
}

.saik_wer {
    padding: 5px;
}

input:focus {
    border: none;
    outline: none;
}

.sas_Fdlld {
    background: rgb(60 60 60);
    padding: 20px;
    border-radius: 10px;
}

.Iu_s414e5.sju_er7-sw.show_sw-yu {
    top: 10rem;
}

.Iu_s414e5.sju_er7-sw {
    position: fixed;
    transition: top 0.5s ease-in-out;
    top: -500px;
    width: 30rem;
    left: 35%;
}

.Iu_s414e5.sju_er7-sP {
    position: fixed;
    transition: top 0.5s ease-in-out;
    top: -500px;
    width: 30rem;
    left: 35%;
}

.Iu_s414e5.sju_er7-sP.show_sw-yu {
    top: 15rem;

}

button.slA_we4W_k {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    background: #5c5c5c;
    color: white;
    transition: background 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

button.slA_we4W_k:hover {
    background: #8d8d8d;
}

.ksA_wws2 {
    width: 100%;
}

.salk_E85r {
    font-size: 0.7rem;
    text-align: center;
    color: #d8d8d8;
    font-style: italic;
}

.aw_swE_Sw {
    padding: 10px;
}

button.slA_we4W_k.W_ert_w {
    background: white;
    color: black;
}

button.slA_we4W_k.W_ert_w:hover {
    background: #ccc;
    color: black;
}

/* On Sale Section */

.saW_EEE_RTdS {
    display: flex;
    align-items: center;
}

.s_WeR_YuuyT {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 2rem;
    padding: 10px 0px;
}

.sA_WErr_Tyu {
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.salW_reT_f {
    width: 100%;
}

.sW_erTTUuKH_dre {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.sW_EEE_TBGG_BnH {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.s_ERRY_ytR_RhD {
    padding: 20px;
}

.slW_ERtYF_r {
    color: white;
    text-align: center;
    padding: 5px;
}

.IS_erT_Yy {
    position: fixed;
    transition: top 0.5s ease-in-out;
    top: -500px;
    width: 30rem;
    left: 35%;
}

.IS_erT_Yy.show_sw-Bu {
    top: 12rem;
}

button.A_weR_tuu {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    background: #5c5c5c;
    color: white;
    transition: background 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.A_weR_tuu:hover {
    background: #8d8d8d;
}

.alks-sawer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sak--gfg44j {
    width: 100%;
}

.Bids-sder-hh45 {
    width: 10rem;
    text-align: left;
}

.salE_RTY_fE {
    padding: 0px 10px;
}

.sW_eeR_TYUuy_TGf {
    display: flex;
    align-items: center;
    justify-content: center;
}

img.sw_ErrTUHGff_DE {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sWFG_hT_TGF {
    width: 2rem;
}

.Bids-sder-hh46 {
    width: 4rem;
    text-align: left;
}

.sask-ffg4 {
    padding: 5px;
    text-decoration: none;
    color: black;
}

.sask-ffg4:hover {
    text-decoration: underline;
}

.s_EeRR_ttg_T {
    width: 7rem;
}

.sW_eRTT_yuutR {
    padding: 5px;
}

.saW_eT_ListSWE {
    text-align: center;
    padding: 10px;
}

.saWW_ERTfgGG_H {
    font-size: 1.5rem;
}

.sAW_rTRTYY_gFGRerE_s {
    padding: 10px;
    font-size: 1.2rem;
}

span.sasW_SwEER_r_Time {
    font-size: 2rem;
    color: black;
}

.S_A_Type_Order {
    font-size: 0.8rem;
    color: #5c5c5c;
}

span.aS_wE_RRT {
    color: #5c5c5c;
}

.saS_WWERT_HBGfgf_GY {
    display: flex;
    color: #1a73e8;
}

.sawwe_dsE_RERTFdf {
    padding: 0px 10px;
    color: black;
}

.sAS_eER_FfF_GGFD {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sasW_ERRTY_HGHJGddf4fd_ss {
    display: flex;
    align-items: center;
    width: 50%;
}

.BiddeR_asASE_s {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0px 10px;
}

.sas_WWW_Ed_SD_RtW_E {
    padding: 0px 10px;
}

.sas_WWW_Ed_SD_RttEE {
    padding: 0px 10px;
}

.sas_WWW_Ed_SD_RtW_E {
    color: #5c5c5c;
}

.sas_WWW_Ed_SD_Rt {
    color: black;
    margin: 0px 5px;
}

.sas_WWW_Ed_SD_RttEE {
    padding: 0px 10px;
    color: #5c5c5c;
}

.sA_WErr_Tyu.saS_wERTTYTY_GfDF_YTY {
    width: 7rem;
}

.saksE_RTTYRdS {
    width: 100%;
    font-size: 2rem;
}

.sas_EERrtTGgGHJHJGG_FDFED4 {
    padding: 15px 0px;
}

.s_saA_SWsr {
    color: #606060;
    font-size: 1rem;
}

.sAS_WERTGGgg_HUu {
    width: 100%;
    padding: 0px 5px;
}

.as-fdtt.sasee_RRTRTgggfD_ff4 {
    width: 50%;
}

.rTfE_Tt1fdE.ShowRefresh {
    display: block;
}

.rTfE_Tt1fdE {
    background: #8f8f8f;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: none;
    transition: background 0.5s ease;
}

.rTfE_Tt1fdE:hover {
    background: #ccc;
}

.sas-R_Tyyu_Tss {
    position: absolute;
    right: 160px;
    top: 195px;
}

.react-datepicker-wrapper {
    width: 100%;
}

input[type="text"] {
    width: 100%;
}

.sa_WWtrtu_UTT {
    text-align: center;
    font-size: 1.2rem;
    color: white;
    padding: 5px;
}

.sas_EERTGG_Ffg_G {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

input.sasWWE_GVgfV_Ddf {
    border: none;
    border-inline: none;
    border-radius: 5px;
    padding: 8px;
}

.sasE_RfdsD_Y {
    padding: 10px;
    border-bottom: solid 0.1px gray;
}

.sas_WWW_Ed_SD_Rt.sEE_RFDFdf_D {
    color: green !important;
}

.sasE_RfdsD_Y {
    font-size: calc(100% - 20%);
}
.info-accordion {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;
  background: hsla(0, 0%, 75%, 0.5);
  -webkit-backdrop-filter: blur(0.5rem);
          backdrop-filter: blur(0.5rem);
}

.info {
  padding: 10px;
  text-align: justify;
}

.info-accordion .accordion-header {
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.info-accordion.active .accordion-header {
  background-color: #f0f0f0;
}

.info-accordion .accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.title-header--sas44 {
  padding: 0px 5px;
}

.accordion-header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.info-accordion.active .accordion-content {
  max-height: 300px;
  /* Adjust this value to fit your content */
}
.alks-sawer {
    padding: 10px;
}

.sak--gfg44j {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bidder-sas45-dg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.sask-ffg4 {
    padding: 5px;
}

.accordion-content.Bids {
    overflow: auto;
}

.sask-fdff44 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sask-ffg44 {
    padding: 20px;
}
.df4g4g-fdfr {
    display: flex;
    align-items: center;
    justify-content: center;
}

.df4g4g-salle-s {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fragment-sasl-h44 {
    padding: 5px;
}

.dksd4h-hgh4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sas--fdty-yy {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.salsk--frtttyy {
    padding: 5px;
    font-size: calc(100% - 15%);
    font-weight: 600;
}


.saslk-ff44g {
    padding: 5px 10px;
}

.sas-gfg11h {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sasl-rr44t-uu {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.time-elapsed-s445s {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.df4g4g-fdfr {
    background: lightblue;
    border-radius: 10px;
    padding: 5px;
    width: 30%;
}

.df4g4g-salle-s {
    background: rgb(173, 230, 211);
    border-radius: 10px;
    padding: 5px;
    width: 30%;
}

.dksd4h-hgh4 {
    padding: 5px 5px;
}

.df4g4g-fdfr {
    border: solid 0.5px #00c0ff;
}

.df4g4g-salle-s {
    border: solid 0.5px rgb(0, 255, 170);
}

.as-fdtt {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saslk-s444fff {
    display: flex;
    align-items: center;
    justify-content: center;
}

a.sasl-dd4 {
    text-decoration: none;
    color: #242424;
}

a.sasl-dd4:hover {
    text-decoration: underline;
}
.saW_EWRTYUjjj_U {
    font-size: 16px;
    margin-bottom: 8px;
}

.sE_Ttg_D {
    font-size: 14px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.saW_EWRTYUjjj_U {
    text-align: center;
    color: white;
}
.SWrT_DSe {
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}
.box-container-skjd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

a.content-skajt-s {
    text-decoration: none;
    color: black;
}

a.content-skajt-s:hover {
    text-decoration: underline;
}
.forbidden {
    height: 100vh;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), rgb(84, 86, 145) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
}

.aw4-aa1w4{
    width: 400px;
}
.saw-errt {
    width: calc(100% - 73%);
}

.ieks-asw {
    width: 100%;
    height: 100vh;
    background: rgb(91, 94, 148);
    background: linear-gradient(0deg, rgba(91, 94, 148, 1) 0%, rgba(178, 198, 255, 1) 54%, rgba(221, 216, 255, 1) 98%);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
}

.aol-aw {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 250px;
    flex-direction: column;
    background: hsl(0 0% 100% / 0.3);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    border-radius: 20px;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
}

.sak-sewr {
    font-size: calc(100% + 30%);
    font-family: Inter, Helvetica, Arial, "Microsoft YaHei", sans-serif;
    font-weight: 700;
}
* {
    font-family: 'Rubik', sans-serif;
    font-size: calc(100% + 0%);
    margin: 0px;
    font-weight: 500;
}

.Toaster {
    padding-top: 80px;
}

.toasterBox {
    padding-top: 80px;
}

.fadeIn {
    opacity: 0;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
svg.svg-inline--fa.fa-bars.icon-navbar {
    display: none;
}

.navbar-uls.show-li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    max-height: 100px;
    position: absolute;
    right: 10px;
}


.navbar-ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbar-uls {
    display: none;
    position: relative;
    top: 50px;
    width: 150px;
    border-radius: 20px;
    background: hsl(0 0% 100% / 0.8);
    -webkit-backdrop-filter: blur(0.5rem);
    backdrop-filter: blur(0.5rem);
    padding: 10px;
}

@media(max-width: 750px) {

    .ikss-jnn {
        padding: 10px;
        width: 100%;
    }


    .nh-sh {
        display: none;
    }

    .ijja-ww {
        display: block;
    }

    .slo-aswe {
        display: none
    }

    svg.svg-inline--fa.fa-bars.icon-navbar {
        display: block;
    }

    .skei-dd {
        padding: 0px 15px;
    }

    .nh-sh.sa-hg {
        position: absolute;
        top: 40px;
        z-index: 60;
        background: #e7e7e7;
        padding: 20px;
        right: 80px;
        border-radius: 15px;
        display: block;
    }

    .saj-rlk {
        display: none;
    }

    .ksaw {
        width: calc(100% - 10%);
    }

    .box-logo {
        padding: 5px 15px;
        padding-left: 20px;
        width: 100%;
    }

    .slik-saw {
        flex-direction: column;
    }

    .sjkj-awew {
        display: none;
    }

    .sip-eww {
        flex-direction: column;
        height: auto;
    }

    @-webkit-keyframes typeout {
        from {
            width: 0;
            opacity: 0;
        }

        to {
            width: 100%;
            opacity: 1;
        }
    }

    @keyframes typeout {
        from {
            width: 0;
            opacity: 0;
        }

        to {
            width: 100%;
            opacity: 1;
        }
    }

    .saki-sawee {
        font-size: 15px;
    }

    .icon-navbar {
        cursor: pointer;
    }

    .navbar-ul {
        display: none;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        background: hsl(0 0% 100% / 0.5);
        -webkit-backdrop-filter: blur(0.5rem);
                backdrop-filter: blur(0.5rem);
        padding: 10px;
    }

    .sjau-saw {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .awe-trty {
        width: auto;
        height: auto;
    }

    .aaa-ees.sla.tokenId {
        width: auto;
        height: 320px;
    }

    .isk-llka {
        left: 0px;
        width: auto;
        padding: 0 10px;
    }

    .kjau {
        left: 0;
        width: 100%;
    }

    .saj-rlk {
        bottom: 0;
        right: auto;
        width: inherit;
    }


    .navbar-uls.show-li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100px;
        max-height: 100px;
        position: relative;
        padding: 25px 0px;
    }

    .sas-awertyu {
        left: auto;
        width: auto;
        padding: 10px;
    }

    box-icon {
        width: 20px;
    }

    .ujsi-kjja {
        width: 100%;
        padding: 0px;
    }

    .kaj-lki {
        padding: 20px;
    }

    .kjai {
        padding: 0px;
    }

    .lku-ujy {
        padding: 20px 20px;
    }

    h1.kkjyt {
        font-size: 40px;
    }

    .kjuy-sg {
        max-width: 100%;
        width: 100%;
    }

    .ika-lkk {
        width: 100%;
        padding: 0px;
    }

    .asia-awwe {
        position: absolute;
        width: 150px;
        left: 40px;
        top: calc(100% - 70%);
    }

    .uhgh-hm {
        height: 280px;
    }

    .kajw {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lka {
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        justify-items: center;
        grid-row-gap: 12px;
        grid-column-gap: 12px;
    }

    h2.as-err {
        font-size: calc(100% + 30%);
    }

    .aki-ee {
        height: auto;
    }

    .navbar-uls {
        display: none;
        position: relative;
        top: 10px;
        left: 0;
        width: 600px;
        background: hsl(0 0% 100% / 0.5);
        -webkit-backdrop-filter: blur(0.5rem);
                backdrop-filter: blur(0.5rem);
        padding: 10px;
    }

    .si-ew {
        width: 100%;
    }

    .navbar-ul.show-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 500px;
        max-height: 500px;
    }

    .navbar-md {
        width: 100%;
        justify-content: center;
    }

    .aksi-saw {
        padding: 0;
    }

    .bsa-sle {
        padding: 10px 40px;
    }

    .skek {
        padding: 0px;
    }

    .aslk-fff {
        display: none;
    }

    .sli-sli {
        height: 80px;
        max-height: 90px;
    }

    .sju-aow {
        width: 100%;
    }

    .search-container {
        position: relative;
        right: 30px;
        width: 100%;
    }

    .skai {
        width: calc(100% - 78%);
    }

    .saj-ees {
        width: 40px;
    }

    .boxasw {
        height: 80px;
        max-height: 90px;
    }

    .skdk a {
        height: 80px;
        max-height: 90px;
    }

    .sales-aee {
        display: none;
    }

    .mn-widthC3 {
        display: none;
    }

    .mn-widthC2 {
        display: none;
    }

    .mn-widthC {
        display: none;
    }

    .iksa {
        display: none;
    }

    .iksa-h {
        display: none;
    }

    .aaa-ees {
        width: 180px;
        height: 300px;
    }

    .skek-es {
        font-size: 30px;
    }

    .allw-rt {
        flex-direction: column;
    }

    div.as4er1 {
        flex-direction: column;
        align-items: center;
    }

    div.yjw-s4g {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.asw-ds4 {
        width: 350px;
    }

    div.alsd-fd144td {
        width: 100%;
    }

    div.info-aswerj {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    div.aksj-dfg44h {
        width: 350px;
    }

    div.aksj-dfg44h {
        width: 350px;
    }

    div.aslf1h-gg {
        font-size: 0.9rem;
        width: 60%;
    }

    div.sak-fg11j4 {
        font-size: 0.8rem;
    }

    div.sl4e-fdgf {
        font-size: 1.4rem;
    }

    .aa45-dsju44 {
        flex-direction: column;
    }

    div.lks-dfg1jgff {
        justify-content: center;
    }

    .asjh_Sae {
        width: 250px;
        left: 20%;
    }

    div.box-logo {
        padding-left: 10px;
    }

    div.search-container {
        right: 20px;
    }

    div.navbar-md {
        width: 100%;
    }

    div.s_Dse_tty {
        width: 100%;
    }

    .sasl_ettY_s {
        text-align: center;
        padding-bottom: 15px;
    }

    div.sasw-weeer {
        padding-bottom: 100px;
    }

    .Iu_s414e5.sju_er7-sw.show_sw-yu {
        width: 80%;
        left: 50px;
        top: 8rem;
    }

    .Iu_s414e5.sju_er7-sw {
        position: fixed;
        transition: top 0.5s ease-in-out;
        top: -500px;
        width: 80%;
        left: 50px;
    }

    .sawnnj {
        flex-direction: column;
        align-items: center;
    }

    .saSW_ERRFffD_GFTY.shuwR_RFdfff_YT {
        left: 50px;
        width: 80%;
    }

    .saSW_ERRFffD_GFTY {
        position: fixed;
        bottom: -500px;
        width: 80%;
        background: white;
        left: 50px;
        height: auto;
        border-radius: 5px;
        transition: bottom 0.5s ease-in-out;
    }

}
.as_Ert_YTYffd_Er {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aswWw_ERtT_YY {
    text-align: center;
}

.aswWw_ERtT_YY.number {
    width: 3%;
}

.aswWw_ERtT_YY.name {
    width: 35%;
}

.aswWw_ERtT_YY.floor {
    width: 10%;
}

.aswWw_ERtT_YY.volume {
    width: 10%;
}

.aswWw_ERtT_YY.change {
    width: 10%;
}

.aswWw_ERtT_YY.item {
    width: 10%;
}

.aswWw_ERtT_YY.owner {
    width: 10%;
}

.aslk_Eer {
    background: rgb(91, 94, 148);
    background: linear-gradient(rgb(60 63 110) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
    padding: 50px;
}

.saW_RrTty_U {
    padding: 5px;
    color: #7d7d7d !important;
}

.sa_Errtty {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tittleA_swrrt {
    font-size: 2.8rem;
}

.as_Ert_YTYffd_Er {
    padding-bottom: 10px;
}

.sas_EEE_Ttg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.asas_WWER_T {
    padding: 10px;
}

.aswWw_ERtT_YY {
    padding: 10px;
}

.YYT_rerTtrT_ {
    border: solid 0.5px gray;
    border-radius: 10px;
    background: white;
    overflow: hidden;
}

.aSdjj-eeer {
    text-decoration: none;
    font-size: 1.2rem;
    padding: 10px 0px;
    color: black;
}

.sas_EEE_Ttg {
    background: white;
    transition: background 0.2s ease;

}

.sas_EEE_Ttg:hover {
    background: rgb(221, 221, 221);

}

.tittleA_swrrt {
    color: #FFFFFF !important;
}

.as_Ert_YTYffd_Er {
    color: #FFFFFF !important;
}

.sa_Errtty.negative {
    color: #ea3943 !important;
}

.sa_Errtty.positive {
    color: #17c671 !important;
}

.asas_WWER_T.name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.saS_EEE_Rrt_R {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 20px;
}

img.as_Errt_YRTre {
    width: 100%;
    height: 100%;
}

.ass_Err {
    padding: 0px 15px;
}

.asas_WWER_T.id {
    width: 3%;
}

.asas_WWER_T.name {
    width: 35%;
}

.asas_WWER_T.floor {
    width: 10%;
}

.asas_WWER_T.volumeChange {
    width: 10%;
}

.asas_WWER_T.volume {
    width: 10%;
}

.asas_WWER_T.items {
    width: 10%;
}

.asas_WWER_T.owners {
    width: 10%;
}

.asas_WWER_T {
    text-align: center;
}

.sa_Errtty {
    padding: 10px;
    text-align: center;
}
.saSW_ERRFffD_GFTY {
    position: fixed;
    bottom: -500px;
    width: 400px;
    background: white;
    left: 500px;
    height: auto;
    border-radius: 5px;
    transition: bottom 0.5s ease-in-out;
}

.sa_WWerDEDSGHHH_fdf {
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.sasEERRT_GgfN_HJHg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.saW_ERTYhhgf_R {
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    background: gray;
    border-bottom: solid 0.1px;
    font-size: calc(100% + 20%);
    position: absolute;
    right: 0;
}

.sas_WEFFDSsds_Ww {
    text-align: center;
    padding: 20px 0px;
}

.saW_ERRTYffd_DEaD {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}

img.ddsEE_TTgF_S {
    width: 30%;
}

.saSW_ERRFffD_GFTY.shuwR_RFdfff_YT {
    bottom: 200px;
}

.sasW_Errrt_GGfgTYTFD_Ss {
    text-align: center;
    color: green !important;
}

span {
    color: black;
}

.saseee_Rreew {
    color: #000000 !important;
}
