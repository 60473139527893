.alks-sawer {
    padding: 10px;
}

.sak--gfg44j {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bidder-sas45-dg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.sask-ffg4 {
    padding: 5px;
}

.accordion-content.Bids {
    overflow: auto;
}

.sask-fdff44 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sask-ffg44 {
    padding: 20px;
}