.jawe-nfts-we {
    width: 100%;
    height: 100%;
}

.asw-ds4 {
    width: 450px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    height: auto;
    max-width: 450px;
    transition: scale 0.5s ease-out;
}

.asw-ds5 {
    width: 450px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    height: 450px;
    max-width: 450px;
    transition: scale 0.5s ease-out;
}

.asw-ds4:hover {
    scale: 1.05;
}

.asw-ds5:hover {
    scale: 1.05;
}

.jawe-nfts-we2 {
    width: calc(100% - 70%);
}

.iks-lk4 {
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(91, 94, 148, 1) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);

}

.as4er1 {
    padding: 50px;

}

.asart-box-sw1:hover,
.asart-box-sw2:hover {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.aas1e4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.a-ffg4d {
    padding: 10px;
}

.aks-rtyui4 {
    background: #b8d3d9;
    padding: 15px;
    border-radius: 10px;
    width: 100px;
    text-align: center;
    font-size: calc(100% - 20%);
    overflow: hidden;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: background 0.2s ease;
}

a.d4s4ryu5 {
    color: gray;
    text-decoration: none;
}

.ss4e5rt {
    width: 450px;
    max-width: 450px;
}

.a45ert-hft {
    width: 450px;
    max-width: 450px;
}

.aa1etu-uhgg {
    padding: 5px;
}

.content-data-slke4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}

.as4rl4 {
    padding: 20px 10px;
}

.aaae4y {
    background: white;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
}

.as4er1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.as144ds {
    padding: 0px 20px;
}

.sase-dff4h {
    padding: 0px 5px;
}

.\35 s44tyi {
    padding: 10px 0px;
}

.a0ert1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.\31 a45e-hhf {
    width: calc(100% - 10%);
    text-align: center;
}

.ska {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calc(100% - 10%);
    padding: 5px 0px;
}

.aw1ruh-fd {
    width: 100%;
    text-align: center;
}

.name-token-history {
    padding: 5px;
}

.ska {
    padding: 5px;
}

.a-sleiik {
    text-decoration: none;
    color: black;
}

.a1d4h-kji {
    background: white;
    border-radius: 20px;
    padding: 10px;
    overflow: scroll;
    height: 360px;
    max-height: 360px;
}

.ask-ersae-t {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.akas-rtg4 {
    width: 100%;
    padding-bottom: 20px;
}

.sdsfj-fdsss {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sja4fg5 {
    padding: 5px;
}

.aks-rtyui4:hover {
    background: #dbf8ff;
}

.sak45h.key {
    font-size: 0.95rem;
    font-weight: 500;
    padding: 5px 0px;
    color: #000000 !important;
}