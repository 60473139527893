.ss5rr {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(5, minmax(0px, 1fr));
    grid-row-gap: 15px;
    grid-column-gap: 15px;
}
.aty55 {
    padding: 10px 5px;
}

a.dfyy5i {
    color: black;
    text-decoration: none;
}

a.ol4a5 {
    color: black;
    text-decoration: none;
    text-align: center;
}

.\37 4a5awertr {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.\37 sw4 {
    width: calc(100% - 5%);
    border-radius: 20px;
}

.\37 sw4:hover {
    background: rgb(255, 255, 255);

}

.as4a1e-tty {
    padding: 20px;
    position: relative;
    bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(235, 235, 235);
    border-radius: 20px;
    justify-content: center;
}

.as54r {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}