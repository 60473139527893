.lka {
    flex-wrap: wrap;
    width: 100%;
}

a.jhsy-aa {
    background: black;
    color: white;
    text-decoration: none;
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
}

a.jhsy-aa:hover {
    background-color: #555555;
}

.akke-rr {
    position: fixed;
    right: 20px;
    top: 100px;
}

.nh-sh {
    display: flex;
    align-items: center;
    justify-content: center;
}

.jusl-asd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.kk-ssqw {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.kjak-hy {
    padding: 10px;
}

.ikss-jnn {
    width: calc(100% - 50%);
    height: auto;
}

.ikj-saaw {
    padding: 5px;
}

.oska-sww {
    padding: 0px 10px;
}

.aaw-sff {
    padding: 0px 15px;
    margin-bottom: 10px;
}

.asa-eee.percentageData {
    width: 70px;
    padding: 0 10px;
}

.asa-eee.wallet {
    width: 420px;
    padding: 0 10px;

}

.iksa-kjsu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: -webkit-fill-available;
}

.asw-rtu {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eth-address {
    width: 100%
}

.percentage {
    width: 100%;
}


textarea.kkj-sk {
    height: 100%;
    width: 100%;
    background: white;
    border: 1px solid;
}

img.sikaj {
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.iku-ujsw {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ijja-ww {
    display: none;
}

.lka,
.saj-rlk {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    grid-row-gap: 15px;
    grid-column-gap: 15px;
}

.saj-rlk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sik-erty {
    flex-wrap: wrap;
    justify-content: right;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
}

.jj-awe {
    transition: scale 0.4s ease;
}

.jj-awe:hover {
    transform: scale(1.02);
}

.saj-rlk {
    border-radius: 10px;
}

.sasol {
    width: 120px;
    max-width: 170px;
}

.sawnnj {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.kjs-sjue {
    text-decoration: none;
    color: black;
}

.slakk-lk {
    position: relative;
    bottom: 785px;
    z-index: 60;
    text-align: center;
    cursor: pointer;
    color: #6a61ff;
    font-weight: bold;
    background: #ffffff73;
}

.sik-erty {
    border-left: solid 0.5px white;
}

.sawnnj {
    border-top: solid 0.5px white;
}

.saj-rlk.sujuk_ERTh-d {
    grid-row-gap: 5px;
}

button.as_ERTffD_s {
    width: 100%;
    border: none;
    background: #9696e1;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background 0.5s ease-in-out;
}

button.as_ERTffD_s:hover {
    background: #bbbbf0;
}

div.s_WrrDS_eRT_D {
    position: absolute;
    transition: bottom 0.5s ease-in-out 0s;
    bottom: -50px;
    right: 0;
    left: 0;
}

div.s_WrrDS_eRT_D.showBuyButton_S {
    bottom: 0px;
}

.S_Wrr_TY_de_R {
    padding: 15px;
}

.aS_Wee_RTgfgfd_D {
    padding: 5px;
}

.iksu-kik {
    padding: 10px 5px;
}

.ass-aawwe {
    padding: 5px;
}

.aso-aa {
    overflow: hidden;
    border-radius: 10px;
}

.sas_WWertt {
    font-size: calc(100% - 10%);
}

.sujuk_ERTh-d_DF {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    grid-row-gap: 15px;
    grid-column-gap: 15px;
}


/* 
* Responsive design 
*/