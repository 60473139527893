.as_Ert_YTYffd_Er {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aswWw_ERtT_YY {
    text-align: center;
}

.aswWw_ERtT_YY.number {
    width: 3%;
}

.aswWw_ERtT_YY.name {
    width: 35%;
}

.aswWw_ERtT_YY.floor {
    width: 10%;
}

.aswWw_ERtT_YY.volume {
    width: 10%;
}

.aswWw_ERtT_YY.change {
    width: 10%;
}

.aswWw_ERtT_YY.item {
    width: 10%;
}

.aswWw_ERtT_YY.owner {
    width: 10%;
}

.aslk_Eer {
    background: rgb(91, 94, 148);
    background: linear-gradient(rgb(60 63 110) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
    padding: 50px;
}

.saW_RrTty_U {
    padding: 5px;
    color: #7d7d7d !important;
}

.sa_Errtty {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tittleA_swrrt {
    font-size: 2.8rem;
}

.as_Ert_YTYffd_Er {
    padding-bottom: 10px;
}

.sas_EEE_Ttg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.asas_WWER_T {
    padding: 10px;
}

.aswWw_ERtT_YY {
    padding: 10px;
}

.YYT_rerTtrT_ {
    border: solid 0.5px gray;
    border-radius: 10px;
    background: white;
    overflow: hidden;
}

.aSdjj-eeer {
    text-decoration: none;
    font-size: 1.2rem;
    padding: 10px 0px;
    color: black;
}

.sas_EEE_Ttg {
    background: white;
    transition: background 0.2s ease;

}

.sas_EEE_Ttg:hover {
    background: rgb(221, 221, 221);

}

.tittleA_swrrt {
    color: #FFFFFF !important;
}

.as_Ert_YTYffd_Er {
    color: #FFFFFF !important;
}

.sa_Errtty.negative {
    color: #ea3943 !important;
}

.sa_Errtty.positive {
    color: #17c671 !important;
}

.asas_WWER_T.name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.saS_EEE_Rrt_R {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 20px;
}

img.as_Errt_YRTre {
    width: 100%;
    height: 100%;
}

.ass_Err {
    padding: 0px 15px;
}

.asas_WWER_T.id {
    width: 3%;
}

.asas_WWER_T.name {
    width: 35%;
}

.asas_WWER_T.floor {
    width: 10%;
}

.asas_WWER_T.volumeChange {
    width: 10%;
}

.asas_WWER_T.volume {
    width: 10%;
}

.asas_WWER_T.items {
    width: 10%;
}

.asas_WWER_T.owners {
    width: 10%;
}

.asas_WWER_T {
    text-align: center;
}

.sa_Errtty {
    padding: 10px;
    text-align: center;
}