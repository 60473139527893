.saW_EWRTYUjjj_U {
    font-size: 16px;
    margin-bottom: 8px;
}

.sE_Ttg_D {
    font-size: 14px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.saW_EWRTYUjjj_U {
    text-align: center;
    color: white;
}