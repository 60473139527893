.search-container {
    position: relative;
}

.sika-wer {
    position: absolute;
    top: 42px;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border-top: none;
    border-radius: 5px;
}

.jusl-asd {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.jusl-asd:hover {
    background-color: #f5f5f5;
}