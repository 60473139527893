.aa4we5-ools {
    position: fixed;
    background: rgb(91, 94, 148);
    background: linear-gradient(rgba(91, 94, 148, 1) 0%, rgba(178, 198, 255, 1) 54%, rgba(91, 94, 148, 1) 98%);
    backdrop-filter: blur(0.7rem);
    height: 100vh;
    width: 350px;
    right: -500px;
    top: 0px;
    font-family: 'Rubik', sans-serif;
    transition: right 0.3s ease-out;
    font-weight: 600;
    z-index: 999;
}

.saw-amount-dsk {
    font-size: calc(100% - 30%);
    padding: 0px 5px;
}

.sign-out {
    cursor: pointer;
}

.we4t-se {
    display: flex;
    align-items: flex-start;
}

.swe-gf4t {
    text-decoration: none;
    color: black;
}

.a45e-ry5 {
    font-size: calc(100% - 30%);
}

.\31 a5wwe {
    position: relative;
    top: 20px;
    left: 25px;
    font-size: calc(100% + 25%);
}

.\34 see-fd {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.content-box-s41.ks-dkkuer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.aa4we5-ools.show-sidebar {
    right: 0px;
}

.sask-ee {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
}

.full-arr0 {
    font-weight: 500;
    text-align: center;
    padding: 10px;
}

.content-box-s41 {
    padding: 5px 0px;
}

.aa-wrr4f {
    padding: 10px;
    position: relative;
    top: 30px;
    font-size: calc(100% + 20%);
}

.full-arr1 {
    padding: 30px;
}

hr.saslo-f22g {
    color: lightslategray;
}

.s112s {
    padding: 2px 10px;
}

.full-arr2 {
    padding: 10px 5px;
}

.balance-saw4 {
    padding: 0px 5px;
}

.saw-amount {
    padding: 0px 5px;
}

.sas-d444-r4 {
    background: #ffffff;
    border-radius: 10px;
    border: solid 0.01px lightslategray;
    width: 80%;
}

.content-box-s41.we4t-se {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    font-size: 1rem;
}

.sask-rrt {
    padding: 5px;
}

.full-arr2.sign-out {
    display: flex;
    align-items: center;
    justify-content: center;
}

.saskj-e {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    right: 40px;
}

.sasl-d11dsw {
    padding: 5px 10px;
}

.slka-dsd22 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    font-size: 1rem;
}

.sas-ee44-gg44 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.balance-saw4 {
    font-size: 0.8rem;
}

.sask-asaws {
    width: 100%;
    height: auto;
    padding: 10px 0px;
}

.sskk-dsd11-f {
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
}

.as4v45t {
    display: flex;
    align-items: center;
}

.sa-452-ee45 {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
}

img.ssla-41-sas11e {
    width: 100%;
}

.amount-ssd-r1 {
    font-size: 0.9rem;
}

.sask-rr44-uo1oy {
    font-size: 0.8rem;
}

.sasl-fdf44-6645 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}