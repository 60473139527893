.fg14k-lj4h {
    background: white;
    height: auto;
    border-radius: 20px;
}

.alsd-fd144td {
    width: 50%;
    padding: 0px 30px;
}


.as41-tty45f {
    width: 100%;
    max-width: 100%;
}

.sas154e-gf5 {
    width: 45px;
    max-width: 45px;
    height: 45px;
    max-height: 45px;
    overflow: hidden;
    border-radius: 50%;
    transition: scale 0.5s ease-in-out;
}

.sas154e-gf5:hover {
    scale: 1.1;
}

.sl4e-fdgf {
    font-weight: 800;
    font-family: 'Public Sans', sans-serif;
    font-size: calc(100% + 60%);
}

.minter-ska45s {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.tokenInfo-sss1d52 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.slk-s1d565 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.crrOwner-asel-f14 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.adkfg-gfd55 {
    font-weight: 700;
    color: rgb(126, 126, 126);
    font-size: calc(100% - 20%);
    padding: 10px;
}

.sajr1s-d {
    font-weight: 500;
    color: black;
    font-size: calc(100% + 40%);
    text-decoration: none;
}

.as22f5-fds {
    padding: 0px;
    font-size: calc(100% + 50%);
}

.s11fgh-dft {
    padding: 30px;
}

.kks-rty1u-fg1 {
    font-size: calc(100% - 10%);
    padding: 15px 5px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

.kks-rty1u {
    font-size: calc(100% + 50%);
    padding: 15px 0px;
    display: flex;
    align-items: center;
}

.lks-dfg1jgff {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: black;
    width: 100%;
}

.ksn-er1j4 {
    padding: 20px;
}

.sas44d-fgh {
    font-size: calc(100% - 10%);
    color: rgb(88, 88, 88);
}

.lask1s-fd44 {
    background: hsla(0, 0%, 84%, 0.8);
    backdrop-filter: blur(0.5rem);
    padding: 20px;
    border-radius: 20px;
}

.info-aswerj {
    padding: 15px 0px;
}

.as41-tty45f {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ska-sas-gt {
    overflow: hidden;
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

img.sas-rytu4 {
    width: 100%;
}

.name-saler {
    padding: 10px;
    font-size: calc(100% + 10%);
    font-weight: 600;
    text-decoration: none;
    color: black;
}

.sakj--dsdre {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 20px;
}

.sas-lrrt4 {
    text-decoration: none;
}

.aa45-dsju44 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button-abyue-1s1 {
    width: 100%;
    padding: 5px;
}

button.sal11ff-rrt4 {
    width: 60%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: calc(100% + 20%);
    transition: background 0.2s ease;
}

button.sal11ff-rrt4:hover {
    background: white;
}

.ask-er44y-s {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sak-platform-sas5 {
    width: 90px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

img.platform-sas44f {
    width: 100%;
}

.askk-saw1ds {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-buttons-asfg41 {
    width: 100%;
}

.sal11ff-rrt4-54 {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    width: 80%;
    background: #5c5c5c;
    color: white;
    transition: background 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sal11ff-rrt4-54:hover {
    background: #8d8d8d;
}

.sask--154hg {
    padding-bottom: 20px;
}

.sak-fg11j4 {
    padding: 0px 5px;
}

.ssfd45-gfgt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aslf1h-gg {
    font-size: calc(100% + 30%);
    padding: 10px 0px;
    color: black;
    width: 100%;
}

.sask-ffdf {
    padding: 5px;
    width: 20%;
}

.box-buttons-asfg41 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.das-sshy {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: calc(100% + 20%);
    transition: background 0.2s ease;
}

.das-sshy:hover {
    background: white;
}

.a5a5ee {
    width: 450px;
}

.bid-sawe155gd-sdrt {
    padding: 20px 0px;
}

.ask--fdsasf {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

svg.svg-inline--fa.fa-users {
    width: 80%;
    height: 80%;
}

.saskFf-f {
    font-size: calc(100% + 30%);
}

.sask--154hg {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.three-points {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

span.asas-r4r4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1px;
    height: 7px;
    max-height: 15px;
    font-size: 2rem;
}

.three-points {
    width: 15px;
    height: 15px;
    align-items: center;
    cursor: pointer;
}

.sasdsdE_TTTygss_WW {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sask_Etty_Ths {
    padding: 0px 5px;
}

/* ss-see */

.as_Erdas {
    display: flex;
    flex-direction: column;
}

input.sh_Err-teew.stye_sew1-sw {
    padding: 8px;
    border-radius: 5px;
    border: none;
    background: #f2f2f2;
    width: 100%;
    box-sizing: border-box;
}

.saik_wer {
    padding: 5px;
}

input:focus {
    border: none;
    outline: none;
}

.sas_Fdlld {
    background: rgb(60 60 60);
    padding: 20px;
    border-radius: 10px;
}

.Iu_s414e5.sju_er7-sw.show_sw-yu {
    top: 10rem;
}

.Iu_s414e5.sju_er7-sw {
    position: fixed;
    transition: top 0.5s ease-in-out;
    top: -500px;
    width: 30rem;
    left: 35%;
}

.Iu_s414e5.sju_er7-sP {
    position: fixed;
    transition: top 0.5s ease-in-out;
    top: -500px;
    width: 30rem;
    left: 35%;
}

.Iu_s414e5.sju_er7-sP.show_sw-yu {
    top: 15rem;

}

button.slA_we4W_k {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    background: #5c5c5c;
    color: white;
    transition: background 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

button.slA_we4W_k:hover {
    background: #8d8d8d;
}

.ksA_wws2 {
    width: 100%;
}

.salk_E85r {
    font-size: 0.7rem;
    text-align: center;
    color: #d8d8d8;
    font-style: italic;
}

.aw_swE_Sw {
    padding: 10px;
}

button.slA_we4W_k.W_ert_w {
    background: white;
    color: black;
}

button.slA_we4W_k.W_ert_w:hover {
    background: #ccc;
    color: black;
}

/* On Sale Section */

.saW_EEE_RTdS {
    display: flex;
    align-items: center;
}

.s_WeR_YuuyT {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 2rem;
    padding: 10px 0px;
}

.sA_WErr_Tyu {
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.salW_reT_f {
    width: 100%;
}

.sW_erTTUuKH_dre {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.sW_EEE_TBGG_BnH {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.s_ERRY_ytR_RhD {
    padding: 20px;
}

.slW_ERtYF_r {
    color: white;
    text-align: center;
    padding: 5px;
}

.IS_erT_Yy {
    position: fixed;
    transition: top 0.5s ease-in-out;
    top: -500px;
    width: 30rem;
    left: 35%;
}

.IS_erT_Yy.show_sw-Bu {
    top: 12rem;
}

button.A_weR_tuu {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    background: #5c5c5c;
    color: white;
    transition: background 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.A_weR_tuu:hover {
    background: #8d8d8d;
}

.alks-sawer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sak--gfg44j {
    width: 100%;
}

.Bids-sder-hh45 {
    width: 10rem;
    text-align: left;
}

.salE_RTY_fE {
    padding: 0px 10px;
}

.sW_eeR_TYUuy_TGf {
    display: flex;
    align-items: center;
    justify-content: center;
}

img.sw_ErrTUHGff_DE {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sWFG_hT_TGF {
    width: 2rem;
}

.Bids-sder-hh46 {
    width: 4rem;
    text-align: left;
}

.sask-ffg4 {
    padding: 5px;
    text-decoration: none;
    color: black;
}

.sask-ffg4:hover {
    text-decoration: underline;
}

.s_EeRR_ttg_T {
    width: 7rem;
}

.sW_eRTT_yuutR {
    padding: 5px;
}

.saW_eT_ListSWE {
    text-align: center;
    padding: 10px;
}

.saWW_ERTfgGG_H {
    font-size: 1.5rem;
}

.sAW_rTRTYY_gFGRerE_s {
    padding: 10px;
    font-size: 1.2rem;
}

span.sasW_SwEER_r_Time {
    font-size: 2rem;
    color: black;
}

.S_A_Type_Order {
    font-size: 0.8rem;
    color: #5c5c5c;
}

span.aS_wE_RRT {
    color: #5c5c5c;
}

.saS_WWERT_HBGfgf_GY {
    display: flex;
    color: #1a73e8;
}

.sawwe_dsE_RERTFdf {
    padding: 0px 10px;
    color: black;
}

.sAS_eER_FfF_GGFD {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sasW_ERRTY_HGHJGddf4fd_ss {
    display: flex;
    align-items: center;
    width: 50%;
}

.BiddeR_asASE_s {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0px 10px;
}

.sas_WWW_Ed_SD_RtW_E {
    padding: 0px 10px;
}

.sas_WWW_Ed_SD_RttEE {
    padding: 0px 10px;
}

.sas_WWW_Ed_SD_RtW_E {
    color: #5c5c5c;
}

.sas_WWW_Ed_SD_Rt {
    color: black;
    margin: 0px 5px;
}

.sas_WWW_Ed_SD_RttEE {
    padding: 0px 10px;
    color: #5c5c5c;
}

.sA_WErr_Tyu.saS_wERTTYTY_GfDF_YTY {
    width: 7rem;
}

.saksE_RTTYRdS {
    width: 100%;
    font-size: 2rem;
}

.sas_EERrtTGgGHJHJGG_FDFED4 {
    padding: 15px 0px;
}

.s_saA_SWsr {
    color: #606060;
    font-size: 1rem;
}

.sAS_WERTGGgg_HUu {
    width: 100%;
    padding: 0px 5px;
}

.as-fdtt.sasee_RRTRTgggfD_ff4 {
    width: 50%;
}

.rTfE_Tt1fdE.ShowRefresh {
    display: block;
}

.rTfE_Tt1fdE {
    background: #8f8f8f;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: none;
    transition: background 0.5s ease;
}

.rTfE_Tt1fdE:hover {
    background: #ccc;
}

.sas-R_Tyyu_Tss {
    position: absolute;
    right: 160px;
    top: 195px;
}

.react-datepicker-wrapper {
    width: 100%;
}

input[type="text"] {
    width: 100%;
}

.sa_WWtrtu_UTT {
    text-align: center;
    font-size: 1.2rem;
    color: white;
    padding: 5px;
}

.sas_EERTGG_Ffg_G {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

input.sasWWE_GVgfV_Ddf {
    border: none;
    border-inline: none;
    border-radius: 5px;
    padding: 8px;
}

.sasE_RfdsD_Y {
    padding: 10px;
    border-bottom: solid 0.1px gray;
}

.sas_WWW_Ed_SD_Rt.sEE_RFDFdf_D {
    color: green !important;
}

.sasE_RfdsD_Y {
    font-size: calc(100% - 20%);
}